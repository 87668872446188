import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, take, takeUntil } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import {
  SiSummary,
  TraceabilityData,
} from 'src/app/models/traceability-state.model';
import { DdrHtmlPdfRequest, DdrAdditionalSectionInfo, LookupOptions, DdrDownloadV2 } from 'src/app/models/ddr-download.model';
import { DownloadService } from 'src/app/services/download.service';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { FormsModule } from '@angular/forms';
import { AddDynamicControlComponent } from './add-dynamic-control';
import { AddDynamicControlFieldsComponent } from "./add-dynamic-control/components/add-dynamic-control-fields/add-dynamic-control-fields.component";
import { Confirmation, ConfirmationService } from 'primeng/api';
import { GenerateDdsModalService } from './services/generate-dds-modal-template.service';

@Component({
  selector: 'app-generate-dds-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    ReactiveFormsModule,
    RiskIconComponent,
    AddDynamicControlComponent,
    TranslocoPipe,
    FormsModule,
    AddDynamicControlFieldsComponent
],
  templateUrl: './generate-dds-modal.component.html',
  styleUrls: ['./generate-dds-modal.component.scss'],
})
export class GenerateDdsModalComponent implements OnInit, OnDestroy {
  INITIAL_PAGE_SUB_HEADER =
    'Please kindly fill in the form and confirm your details below. Afterward, you may download it as a PDF for your DDS reporting.';
  FINAL_PAGE_SUB_HEADER =
    'You may download it as a PDF for your DDS reporting.';

  isFinalPage: boolean = false;
  qrData = 'https://agridence.com/';

  ddsFormGroup!: FormGroup;
  riskTypes = RiskTypesEnum;
  primaryActionText = 'DASHBOARD.DDR.CONFIRM';

  destroyed$ = new Subject<void>();
  totalPlantations = 0;
  totalPoints = 0;
  totalPolygons = 0;
  totalHectarage = 0;
  totalHectarageFormatted: any;
  totalVolumeMt = 0;
  treeCoverLossTotal = 0;
  wdpaTotal = 0;

  headerShippingInstruction = [
    { title: 'Land Legality', property: 'country' },
    { title: 'Env. Protection', property: 'someOtherProperty' },
    { title: '3PR & FPIC', property: 'someOtherProperty' },
    { title: 'Labour Rights', property: 'someOtherProperty' },
    { title: 'Tax, AC, TCR', property: 'someOtherProperty' },
  ];


  headerRiskLayers = [
    { title: 'Tree Cover Loss', property: 'country' },
    { title: 'SBTN', property: 'someOtherProperty' },
    { title: 'WDPA', property: 'someOtherProperty' },
    { title: 'Thai Forest Cover 2020', property: 'someOtherProperty' },
    { title: 'Thai Prot. Areas', property: 'someOtherProperty' },
    { title: 'Thai Res. Forest', property: 'someOtherProperty' },
    { title: 'Forest Area, Indonesia', property: 'someOtherProperty' },
    { title: 'EU Forest Observatory', property: 'someOtherProperty' },
    { title: 'Planet', property: 'someOtherProperty' },
    { title: 'Mapping Quality', property: 'someOtherProperty' },
  ];

  harmonisedSystemOptions = [{}];

  harmonisedSystemCodeValue = '40012200';

  sisWithTreeCoverLoss: SiSummary[] = [];
  sisWithWdpa: SiSummary[] = [];

  get subHeader() {
    return this.isFinalPage
      ? this.FINAL_PAGE_SUB_HEADER
      : this.INITIAL_PAGE_SUB_HEADER;
  }

  get isSummaryItem1Checked() {
    return this.ddsFormGroup.get('isSummaryItem1Checked') as FormControl;
  }

  get isSummaryItem2Checked() {
    return this.ddsFormGroup.get('isSummaryItem2Checked') as FormControl;
  }

  get eoriNumber() {
    return this.ddsFormGroup.get('eoriNumber') as FormControl;
  }

  get processorName() {
    return this.ddsFormGroup.get('processorName') as FormControl;
  }

  get processDate() {
    return this.ddsFormGroup.get('processDate') as FormControl;
  }

  get includeInEudrPackage() {
    return this.ddsFormGroup.get('includeInEudrPackage') as FormControl;
  }

  get additionalSectionsCtrls() {
    return (this.ddsFormGroup.get('additionalSections') as FormArray).controls;
  }

  get selectedSis(): TraceabilityData[] {
    return this.dialogConfig.data;
  }

  get riskOverlaps() {
    return [
      {
        riskType: RiskTypesEnum.TREE_COVER_LOSS,
        label: this.translocoService.translate('DASHBOARD.DDR.TREE_COVER_LOSS'),
        detailList: this.sisWithTreeCoverLoss,
      },
      {
        riskType: RiskTypesEnum.WDPA,
        label: this.translocoService.translate('DASHBOARD.DDR.PROTECTED_AREAS'),
        detailList: this.sisWithWdpa,
      },
    ];
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private downloadService: DownloadService,
    private utilityService: UtilityService,
    public eventStateService: EventStateService,
    private decimalPipe: DecimalPipe,
    private translocoService: TranslocoService,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private ddsModalTemplateService: GenerateDdsModalService
  ) { }

  ngOnInit(): void {
    this.initializeHsCodesOptions();
    this.initializeDdsFormGroup();
    this.initializeSiSummaries();
    this.listenToDownloadEvent();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeHsCodesOptions() {
    // this.harmonisedSystemOptions = this.utilityService.getHSCodesDropdownOptions()
    this.utilityService.getHSCodesDropdownOptions().then((codes) => {
      this.harmonisedSystemOptions = codes;
    });
  }

  listenToDownloadEvent() {
    this.eventStateService.isDdrDownloaded$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isDownloaded) => {
        if (isDownloaded) {
          this.dialogRef.close();
        }
      });
  }

  initializeSiSummaries() {
    const summaries: SiSummary[] = [
      ...this.selectedSis.map((selectedSi) => selectedSi.summary!),
    ];

    this.sisWithTreeCoverLoss = [
      ...summaries.filter(
        (summary) =>
          summary?.risks && summary?.risks[RiskTypesEnum.TREE_COVER_LOSS]
      ),
    ];

    this.sisWithWdpa = [
      ...summaries.filter(
        (summary) => summary?.risks && summary?.risks[RiskTypesEnum.WDPA]
      ),
    ];

    this.totalPoints = this.utilityService.calculateSum(
      summaries,
      'total_points'
    );

    this.totalPolygons = this.utilityService.calculateSum(
      summaries,
      'total_polygons'
    );

    this.totalPlantations = this.totalPoints + this.totalPolygons;

    this.totalHectarage = this.utilityService.calculateSum(
      summaries,
      'total_area'
    );

    this.totalHectarageFormatted = this.decimalPipe.transform(this.totalHectarage, '1.2-2');

    this.totalVolumeMt = this.utilityService.calculateSum(
      this.selectedSis,
      'si_weight'
    );

    this.treeCoverLossTotal = this.utilityService.calculateSum(
      [
        ...this.sisWithTreeCoverLoss.map(
          (si) => si.risks[RiskTypesEnum.TREE_COVER_LOSS]
        ),
      ],
      'overlapAreaPerc'
    );

    this.wdpaTotal = this.utilityService.calculateSum(
      [...this.sisWithWdpa.map((si) => si.risks[RiskTypesEnum.WDPA])],
      'overlapAreaPerc'
    );

    const totalVolumeKg = this.totalVolumeMt * 1000;
    this.ddsFormGroup.get('commodityInformation.quantity')?.setValue(this.decimalPipe.transform(totalVolumeKg, '1.0-0'));
  }

  initializeDdsFormGroup() {
    this.ddsFormGroup = this.formBuilder.group({
      isSummaryItem1Checked: [false],
      isSummaryItem2Checked: [false],
      companyInformation: this.formBuilder.group({
        factoryName: ['', [Validators.required, Validators.maxLength(250)]],
        factoryAddress: ['', [Validators.required, Validators.maxLength(250)]],
        additionalControls: new FormArray([])
      }),
      commodityInformation: this.formBuilder.group({
        quantity: [''],
        harmonisedSystemCodes: [[this.harmonisedSystemCodeValue]],
        tradeName: [this.translocoService.translate('DASHBOARD.DDR.NATURAL_RUBBER'), [Validators.maxLength(250)]],
        scientificName: [this.translocoService.translate('DASHBOARD.DDR.HEVEA_BRASILLIENSIS'), [Validators.maxLength(250)]],
        additionalControls: new FormArray([])
      }),
      additionalSections: new FormArray([]),
      processorName: ['', [Validators.maxLength(250)]],
      processDate: new Date(),
      includeInEudrPackage: [false]
    });
  }

  onConfirmClicked() {
    if (this.isFinalPage) {
      this.eventStateService.isDownloadDdrLoading = true;
      const downloadHtmlPayload = this.getDdrDownloadHtmlPayload();
      this.ddsModalTemplateService.generateDdrHtmlPayload(downloadHtmlPayload).pipe(take(1)).subscribe((html: string) => {
        const request: DdrDownloadV2 = {
          html: html,
          si_numb: downloadHtmlPayload.si_numb?.length > 0 ? downloadHtmlPayload.si_numb[0] : '',
          include_eudr_package: downloadHtmlPayload.includeInEudrPackage ?? false,
          sidetail_id: downloadHtmlPayload.selected_si?.length > 0 ? downloadHtmlPayload.sidetail_id![0] : 0
        }
        this.downloadService.downloadDdrV2(request);
      });
    }

    this.isFinalPage = true;
    this.primaryActionText = 'DASHBOARD.DDR.DOWNLOAD';
  }

  getAsFormArray(form: AbstractControl | null) {
    return form ? form as FormArray : null;
  }

  getAsForm(form: AbstractControl | null) {
    return form ? form as FormGroup : null;
  }

  getDdrDownloadHtmlPayload(): DdrHtmlPdfRequest {
    const payload = {
      si_numb: this.selectedSis.map((si) => si.si_number!),
      sidetail_id: this.selectedSis.map((si) => si.sidetail_id!),
      selected_si: this.selectedSis,
      total_hectarage: this.totalHectarage,
      tree_cover_loss: this.treeCoverLossTotal,
      protected_areas: this.wdpaTotal,
      summary_data_collected: this.isSummaryItem1Checked.value,
      summary_produced: this.isSummaryItem2Checked.value,
      processor_name: this.processorName.value,
      process_date: this.datePipe.transform(this.processDate.value, 'yyyy-MM-dd')!,
      polygons: this.totalPolygons,
      gps_point: this.totalPoints,
      factory_name: this.ddsFormGroup.get('companyInformation.factoryName')?.value,
      factory_address: this.ddsFormGroup.get('companyInformation.factoryAddress')?.value,
      company_info: [
        {
          title: this.translocoService.translate('DASHBOARD.DDR.OPERATORS_NAME'),
          value: this.ddsFormGroup.get('companyInformation.factoryName')?.value,
        },
        {
          title: this.translocoService.translate('DASHBOARD.DDR.OPERATORS_ADDRESS'),
          value: this.ddsFormGroup.get('companyInformation.factoryAddress')?.value,
        },
      ],
      commodity_info: [
        {
          title: this.translocoService.translate('DASHBOARD.DDR.HARMONISED_SYSTEM_CODE'),
          value: this.ddsFormGroup.get('commodityInformation.harmonisedSystemCodes')?.value?.join(', ')
        },
        {
          title: this.translocoService.translate('DASHBOARD.DDR.TRADE_NAME'),
          value: this.ddsFormGroup.get('commodityInformation.tradeName')?.value,
        },
        {
          title: this.translocoService.translate('DASHBOARD.DDR.SCIENTIFIC_NAME'),
          value: this.ddsFormGroup.get('commodityInformation.scientificName')?.value,
        },
        {
          title: this.translocoService.translate('DASHBOARD.DDR.QUANTITY_KG'),
          value: this.ddsFormGroup.get('commodityInformation.quantity')?.value,
        },
      ],
      refresh: true,
      includeInEudrPackage: this.ddsFormGroup.get('includeInEudrPackage')?.value,
      additional_info: [],
      total_volume: this.totalVolumeMt,
      risk_overlaps: this.riskOverlaps,
    } as DdrHtmlPdfRequest;

    this.ddsFormGroup.get('commodityInformation.additionalControls')?.value.forEach((value: LookupOptions)  => {
      payload.commodity_info.push(value);
    });
    this.ddsFormGroup.get('companyInformation.additionalControls')?.value.forEach((value: LookupOptions)  => {
      payload.company_info?.push(value);
    });
    payload.additional_info = this.ddsFormGroup.get('additionalSections')?.value?.map((value: { sectionHeaderName: string, additionalControls: LookupOptions[] }) => {
      const newValue: DdrAdditionalSectionInfo = {
        section: value.sectionHeaderName,
        info: value.additionalControls
      }

      return newValue;
    });

    return payload;
  }

  onBackClicked() {
    this.primaryActionText = 'DASHBOARD.DDR.CONFIRM';
    this.isFinalPage = false;
  }

  onAddControl(group: string, control: FormGroup, sectionIndex?: number) {
    if (sectionIndex !== undefined) {
      ((this.ddsFormGroup.get(group) as FormArray).controls[sectionIndex].get('additionalControls') as FormArray).push(control);
    } else {
      (this.ddsFormGroup.get(group) as FormArray).push(control);
    }
  }
  
  onRemoveControl(group: string, index: number, sectionIndex?: number) {
    if (sectionIndex !== undefined) {
      const additionalControls = ((this.ddsFormGroup.get(group) as FormArray).controls[sectionIndex].get('additionalControls') as FormArray);
      if (additionalControls.length > 1) {
        ((this.ddsFormGroup.get(group) as FormArray).controls[sectionIndex].get('additionalControls') as FormArray).removeAt(index);
      } else {
        const message1 = `${this.translocoService.translate('DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.MESSAGE_LINE1')}`;
        const message2 = `${this.translocoService.translate('DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.MESSAGE_LINE2')}`;
        this.confirmationService.confirm({
          message: message1 + "<br>" + message2,
          header: this.translocoService.translate('DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.HEADER'),
          defaultFocus: 'reject',
          acceptLabel: this.translocoService.translate('DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.ACCEPT_BUTTON_LABEL'),
          rejectLabel: this.translocoService.translate('DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.REJECT_BUTTON_LABEL'),
          rejectIcon: '_',
          acceptIcon: '_',
          accept: () => { (this.ddsFormGroup.get(group) as FormArray).removeAt(sectionIndex) },
          reject: () => { }
        } as Confirmation);
      }
    } else {
      (this.ddsFormGroup.get(group) as FormArray).removeAt(index);
    }
  }

  onAddSection(control: FormGroup) {
    const fb = this.formBuilder.group({
      sectionHeaderName: new FormControl<string | null>('', [Validators.required, Validators.maxLength(250)]),
      additionalControls: this.formBuilder.array([control])
    });

    this.onAddControl('additionalSections', fb);
  }

  onRemoveSection(group: string) {
    this.ddsFormGroup.removeControl(group);
  }
}

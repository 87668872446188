<ng-container>
    <div *ngIf="mode === ddsModalModelsEnum.SUBMIT">
        <div *ngIf="isLoading" class="loading-overlay">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div *ngIf="!isLoading">
            <div class="flex flex-column mx-4 mb-6">
                <div class="text-xl font-semibold py-4">
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.PREFILL_SUBHEADER" | transloco }}
                </div>
                <div>
                    <p-checkbox></p-checkbox>
                    <label for="doNotAskAgain" class="ml-2">
                        {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.DO_NOT_ASK_AGAIN" | transloco }}
                    </label>
                </div>
            </div>
            <div class="flex justify-content-end modal-action-section">
                <p-button class="mr-4 mt-4" [outlined]="true"
                    (onClick)="openDdsSubmissionForm(true, false, ddsModalModelsEnum.SUBMIT)">
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.NO" | transloco }}
                </p-button>
                <p-button class="mr-4 mt-4" (onClick)="openDdsSubmissionForm(true, true, ddsModalModelsEnum.SUBMIT)">
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.YES" | transloco }}
                </p-button>
            </div>
        </div>

    </div>

    <div *ngIf="mode === ddsModalModelsEnum.WITHDRAW">
        <div class="flex flex-column mx-4 mb-4">
            <div class="text-xl font-semibold py-4">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.WITHDRAW_SUBHEADER" | transloco }}
            </div>
            <div class="text-xl font-semibold py-2">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.PROCEED_MSG" | transloco }}
            </div>
            <div class="flex justify-content-end modal-action-section">
                <p-button class="mr-4 mt-4" [outlined]="true"
                    (onClick)="openDdsSubmissionForm(false, prefill, ddsModalModelsEnum.VIEW, dialogData.form)">
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.PREVIOUS" | transloco }}
                </p-button>
                <p-button class="mr-4 mt-4" (onClick)="withdrawDDS()">
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.YES_PROCEED" | transloco }}
                </p-button>
            </div>
        </div>
    </div>

    <div *ngIf="mode === ddsModalModelsEnum.CONFIRM_SUBMISSION">
        <div class="flex flex-column mx-4 mb-4">
            <div class="text-xl font-semibold py-4">
                {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.SUBMISSION_SUBHEADER" | transloco }}
            </div>
            <div class="pt-2 pb-6">
                <input type="checkbox" [(ngModel)]="confidential_geolocation" class="mr-2">
                <span>
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.SUBMISSION_SUBHEADER_NOTE" | transloco
                    }}
                </span>
            </div>
            <div class="flex justify-content-end modal-action-section">
                <p-button class="mr-4 mt-4" [outlined]="true" [disabled]="isLoading"
                    (onClick)="openDdsSubmissionForm(true, prefill, ddsModalModelsEnum.SUBMIT, dialogData.form)">
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.PREVIOUS" | transloco }}
                </p-button>
                <p-button class="mr-4 mt-4" (onClick)="createDDS()" [disabled]="isLoading">
                    {{ "DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_CONFIRMATION.CONFIRM" | transloco }}
                </p-button>
                <p-progressSpinner *ngIf="isLoading" styleClass="mt-5 small-spinner p-button-icon p-button-loading-icon"></p-progressSpinner>
            </div>
        </div>
    </div>
</ng-container>
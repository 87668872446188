import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TraceabilityData } from '../models/traceability-state.model';
import { ToastProps } from '../models/toast.model';
import { HttpResponse } from '@angular/common/http';
import { Observable, Subscription, take } from 'rxjs';
import { PostMessageTypesEnum } from '../enums/post-message-types.enum';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private hsCodesUrl = 'https://assets.agridence.com/agd-traceability/hscodes/all/v1/codes.json';
  private euIsHsCodesUrl = 'https://assets.agridence.com/agd-traceability/hscodes/all/euis/euis-hscodes.json';

  constructor(private datePipe: DatePipe) {}

  async getHSCodesDropdownOptions(): Promise<{ label: string; value: string }[]> {
    const response = await fetch(this.hsCodesUrl);
    const hsCodes = await response.json();
    return hsCodes.map((code: any) => ({
      label: `${code.HSCode}: ${code.Description}`,
      value: code.HSCode
    }));
  }

  async getEuIsHSCodesDropdownOptions(): Promise<{ label: string; value: string }[]> {
    const response = await fetch(this.euIsHsCodesUrl);
    const hsCodes = await response.json();
    return hsCodes.map((code: any) => ({
      label: `${code.hscode}: ${code.description}`,
      value: code.hscode
    }));
  }

  getDateMonth(date: string) {
    return this.datePipe.transform(date, 'MMM yyyy');
  }

  getSiListArray(siList: TraceabilityData[]) {
    if (!siList?.length) {
      return [];
    }
    const siSet = new Set<string>();
    siList.forEach((si) => {
      siSet.add(si.si_number!);
    });
    return Array.from(siSet);
  }

  getSiDetailListArray(siList: TraceabilityData[]) {
    if (!siList?.length) {
      return [];
    }
    const siSet = new Set<number>();
    siList.forEach((si) => {
      siSet.add(si.sidetail_id!);
    });
    return Array.from(siSet);
  }

  constructMultipleEmailsQuery(emails: string[]) {
    let emailListQuery = '';
    // TODO: need to align in BE
    const queryProp = 'emails';
    emails.forEach((email) => {
      emailListQuery += `&${queryProp}=${email}`;
    });

    return emailListQuery;
  }

  constructMultipleSiQuery(siList: (number | string)[]) {
    let siListQuery = '';
    // TODO: need to align in BE
    const queryProp = 'si';
    siList.forEach((si, index) => {
      if (index === 0) {
        siListQuery += `?${queryProp}=${si}`;
      } else {
        siListQuery += `&${queryProp}=${si}`;
      }
    });

    return siListQuery;
  }

  constructMultipleSiDetailQuery(siList: (number | string)[]) {
    let siDetailListQuery = '';
    // TODO: need to align in BE
    const queryProp = 'sidetail_id';
    siList.forEach((si, index) => {
      if (index === 0) {
        siDetailListQuery += `?${queryProp}=${si}`;
      } else {
        siDetailListQuery += `&${queryProp}=${si}`;
      }
    });

    return siDetailListQuery;
  }

  areArraysEqual<T>(array1: T[], array2: T[]): boolean {
    return (
      array1.length === array2.length &&
      array1.every((value, index) => value === array2[index])
    );
  }

  emitToast(toast: ToastProps) {
    this.emitPropToParent('type', PostMessageTypesEnum.TOAST, 'toast', toast);
  }

  emitPropToParent<T, U>(key1: string, value1: T, key2?: string, value2?: U) {
    const message = {
      ...(key1 && { [key1]: value1 }),
      ...(key2 && { [key2]: value2 }),
    };

    window.parent.postMessage(message, '*');
  }

  calculateSum<T>(data: T[], property: keyof T) {
    if (!data.includes(undefined as any)) {
      return data.reduce((total: number, object: T) => {
        const value =
          object[property] === undefined || object[property] === null
            ? 0
            : Number(object[property]);
        return total + value;
      }, 0);
    }

    return 0;
  }

  getFileNameFromHeaders(response: HttpResponse<Blob>) {
    const contentDisposition =
      response.headers.get('content-disposition') || '';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let fileName = 'download';
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }

    return fileName;
  }

  getFileNameFromUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1].split('?')[0];
  }

  getPlantationCodesArray(plantationList: any[], plantationCodeProp: string) {
    const plantationCodes = new Set<string>();
    plantationList.forEach((plantation) => {
      plantationCodes.add(plantation[plantationCodeProp]);
    });
    return Array.from(plantationCodes);
  }

  unsubscribeSubs(subs: Subscription[]) {
    subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  retrieveFromObservable(sub: Observable<unknown>): unknown {
    let values = null;
    if (sub) {
      sub.pipe(take(1)).subscribe(value => values = value);
    }

    return values;
  }
}

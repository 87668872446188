import { Mapping } from "./traceability-state.model";

export interface Settings {
    id: string;
    company_id: string;
    company_name: string;
    mapping?: Mapping;
    created_at: string;
    last_modified_at: string;
}

export interface SettingsState {
    settings: Settings | null;
}

export const INITIAL_SETTINGS_STATE: SettingsState = {
    settings: null
}
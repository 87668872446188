import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { DDSModalModesEnum } from 'src/app/enums/traceability-table-tabs.enum';
import { DdsSubmissionConfirmationModalComponent } from './dds-submission-confirmation-modal/dds-submission-confirmation-modal.component';
import * as countries from 'countries-list';
import { MessageService } from 'primeng/api';
import { catchError, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { GeoJson, GroupedData, Feature } from 'src/app/models/dds-submission-payload.model';
import { firstValueFrom } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import { UserInfoStateService } from 'src/app/services/state-service/user-info-state.service';

export const EU_COUNTRY_CODES: string[] = [
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ',
  'DK', 'EE', 'FI', 'FR', 'DE', 'GR',
  'HU', 'IE', 'IT', 'LV', 'LT', 'LU',
  'MT', 'NL', 'PL', 'PT', 'RO', 'SK',
  'SI', 'ES', 'SE',
];

@Component({
  selector: 'app-dds-submission-form-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    TranslocoPipe,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './dds-submission-form-modal.component.html',
  styleUrls: ['./dds-submission-form-modal.component.scss']
})
export class DdsSubmissionFormModalComponent {
  rowData: any;
  editable: boolean = false;
  submittedData: any;

  ddsFormGroup!: FormGroup;
  prefill = false;

  ddsModalModesEnum = DDSModalModesEnum;
  mode = DDSModalModesEnum.SUBMIT;

  activityOptions: any[] = [
    { label: 'Import', value: 'IMPORT' },
    { label: 'Export', value: 'EXPORT' }
  ];
  countryOptions: any[] = [];
  euCountryOptions: any[] = [];
  hsCodesOptions: any[] = [{}];
  companyName: string | undefined;
  destroyed$ = new Subject<void>();

  dataLoading: boolean = false;
  tableLoading: boolean = false;
  geoJsonData: any;
  groupedGeoData: GroupedData[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    public eventStateService: EventStateService,
    private dialogService: DialogService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private userInfoStateService: UserInfoStateService,
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    this.getUserInfo();
    this.prefill = this.dialogConfig.data.prefill;
    this.editable = this.dialogConfig.data.editable;
    this.rowData = this.dialogConfig.data.rowData
    this.mode = this.dialogConfig.data.mode;
    this.initializeCountryOptions();
    this.initializeFormGroup();
    this.initializeHsCodesOptions();
    this.retrieveGeoJsonData();
  }

  getUserInfo() {
    this.userInfoStateService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userInfo) => {
        this.companyName = userInfo?.company?.name || '';
      });
  }

  retrieveGeoJsonData() {
    this.tableLoading = true;
    this.dashboardService.getGeoJsonData(this.rowData.si_number, this.rowData.sidetail_id, true)
      .pipe(
        takeUntil(this.destroyed$),
        catchError(error => {
          this.tableLoading = false;
          console.error('Error fetching GeoJSON data:', error);
          return [];
        })
      )
      .subscribe((res) => {
        const blob = res.body as Blob;
        let fileContent: string | undefined;
        const reader = new FileReader();
        reader.onload = (event) => {
            fileContent = event.target?.result as string;
            this.geoJsonData = JSON.parse(fileContent);
            if(this.geoJsonData){
              this.groupedGeoData = this.groupGeoData(this.geoJsonData);
              this.tableLoading = false;
            }
        };
        reader.readAsText(blob);
        
      });
  }

  groupGeoData(geoData: GeoJson): GroupedData[] {
    const grouped = geoData.features.reduce((accumulator: { [key: string]: GroupedData }, feature: Feature) => {
      const { ProducerCountry, ProducerName, Area } = feature.properties;
      const key = `${ProducerCountry}-${ProducerName}`;

      if (accumulator[key]) {
        accumulator[key].Areas.push(Area);
      } else {
        accumulator[key] = {
          ProducerCountry,
          ProducerName,
          Areas: [Area]
        };
      }

      return accumulator;
    }, {});

    return Object.values(grouped);
  }

  getTotalArea(areas: number[]): number {
    return parseFloat(areas.reduce((acc, area) => acc + area, 0).toFixed(2));
  }

  initializeCountryOptions() {
    this.countryOptions = Object.values(countries.countries)
      .map((country: any) => {
        const countryCode = countries.getCountryCode(country.name);
        return { label: country.name, value: country.name, code: countryCode };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    this.euCountryOptions = Object.values(countries.countries)
      .filter((country: any) => {
        const countryCode = countries.getCountryCode(country.name);
        return typeof countryCode === 'string' && EU_COUNTRY_CODES.includes(countryCode);
      })
      .map((country: any) => {
        const countryCode = countries.getCountryCode(country.name);
        return { label: country.name, value: country.name, code: countryCode };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }


  initializeHsCodesOptions() {
    this.utilityService.getEuIsHSCodesDropdownOptions().then((codes) => {
      this.hsCodesOptions = codes;
      if (this.hsCodesOptions.length > 0) {
        this.ddsFormGroup.get('hsCode')?.setValue(this.hsCodesOptions[0].value);
      }
    });
  }

  initializeFormGroup() {
    this.dataLoading = true;
    this.ddsFormGroup = this.dialogConfig.data.form ? this.dialogConfig.data.form : new FormGroup({
      prefill: new FormControl(this.prefill),
      companyInternalRefNo: new FormControl(''),
      activity: new FormControl('IMPORT', Validators.required),
      operatorName: new FormControl(this.companyName, Validators.required),
      operatorCountry: new FormControl('', Validators.required),
      operatorISOCode: new FormControl('', Validators.required),
      activityCountry: new FormControl(''),
      entryCountry: new FormControl(''),
      exitCountry: new FormControl(''),
      additionalInfo: new FormControl(''),
      hsCode: new FormControl(this.hsCodesOptions.length > 0 ? this.hsCodesOptions[0].value : ''),
      goodsDescription: new FormControl('', Validators.required),
      scientificName: new FormControl(''),
      commonName: new FormControl(''),
      netMass: new FormControl(this.rowData.si_weight * 1000, Validators.required),
      volume: new FormControl(''),
      supplementaryUnits: new FormControl(''),
      area: new FormControl(
        this.rowData.summary.error === null ? this.rowData.summary.total_area : ''
      ),
      producerName: new FormControl(this.rowData.producer, Validators.required),
      productionCountry: new FormControl(this.rowData.summary.error === null ? this.getCountryValue(this.rowData.summary.countries[0]) : this.countryOptions[0].value),
      productionTotalArea: new FormControl(this.rowData.summary.error === null ? this.rowData.summary.total_area : '')
    });

    this.ddsFormGroup.get('activity')?.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe((activity) => {
      this.handleActivityChange(activity);
    });

    this.populateFormIfNotEditable().then(() => {
      this.dataLoading = false;
    });
  }

  mapResponseToFormData(response: any): any {
    return {
      prefill: response.description_of_goods || '',
      companyInternalRefNo: response.company_internal_reference_number || '',
      activity: response.activityType || 'IMPORT',
      operatorName: this.companyName || '',
      operatorCountry: this.getCountryName(response.country_of_activity) || '',
      operatorISOCode: response.country_of_activity || '',
      goodsDescription: response.description_of_goods || '',
      netMass: response.weight_of_goods ? parseFloat(response.weight_of_goods): '',
      area: response.geojson?.features[0]?.properties?.Area || '',
      producerName: response.producer_name || '',
      productionCountry: response.geojson?.features[0]?.properties?.ProducerCountry || '',
      productionTotalArea: response.geojson?.features[0]?.properties?.Area || ''
    };
  }

  async populateFormIfNotEditable() {
    if (!this.editable) {
      if (this.submittedData) {
        this.ddsFormGroup.patchValue(this.mapResponseToFormData(this.submittedData));
      } else {
        const payload = {
          foreign_key: this.rowData.sidetail_id.toString(),
          filepath: "submission_payload_url_json"
        };

        try {
          const response = await firstValueFrom(this.dashboardService.generateEuIsRecord(payload));

          if (response.submission_payload_url_json) {
            const fetchResponse = await fetch(response.submission_payload_url_json);
            this.submittedData = await fetchResponse.json();

            this.ddsFormGroup.patchValue(this.mapResponseToFormData(this.submittedData));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }
  }

  getCountryValue(countryFromData: string): string {
    const matchedCountry = this.countryOptions.find(
      option => option.value === countryFromData || option.label.toLowerCase() === countryFromData.toLowerCase()
    );

    return matchedCountry ? matchedCountry.value : this.countryOptions[0].value;
  }

  getCountryName(isoCode: string): string {
    const country = countries.countries[isoCode.toUpperCase() as keyof typeof countries.countries];
    return country ? country.name : 'Unknown Country';
  }

  get activity() {
    return this.ddsFormGroup.get('activity')?.value;
  }

  handleActivityChange(activity: string) {
    const exitCountryControl = this.ddsFormGroup.get('exitCountry');
    const activityCountryControl = this.ddsFormGroup.get('activityCountry');
    const entryCountryControl = this.ddsFormGroup.get('entryCountry');
    exitCountryControl?.setValue('');
    activityCountryControl?.setValue('');
    entryCountryControl?.setValue('');

    if (activity === 'EXPORT') {
      activityCountryControl?.clearValidators();
      entryCountryControl?.clearValidators();
      exitCountryControl?.setValidators(Validators.required);
    } else if (activity === 'IMPORT') {
      exitCountryControl?.clearValidators();
      activityCountryControl?.setValidators(Validators.required);
      entryCountryControl?.setValidators(Validators.required);
    }
    exitCountryControl?.updateValueAndValidity();
    activityCountryControl?.updateValueAndValidity();
    entryCountryControl?.updateValueAndValidity();
  }

  onCountryChange(event: any) {
    const selectedCountry = event.value;
    const selectedCountryData = this.countryOptions.find(country => country.value === selectedCountry);

    if (selectedCountryData) {
      this.ddsFormGroup.get('operatorISOCode')?.setValue(selectedCountryData.code);
    }
  }

  copyOperatorCountry() {
    if (this.activity === 'IMPORT') {
      this.ddsFormGroup.get('activityCountry')?.setValue(this.ddsFormGroup.get('operatorCountry')?.value);
      this.ddsFormGroup.get('entryCountry')?.setValue(this.ddsFormGroup.get('operatorCountry')?.value);
    } else if (this.activity === 'EXPORT') {
      this.ddsFormGroup.get('exitCountry')?.setValue(this.ddsFormGroup.get('operatorCountry')?.value);
    }
  }

  openConfirmationModal(mode: DDSModalModesEnum, editable: boolean) {
    this.closeModal();
    this.dialogService.open(DdsSubmissionConfirmationModalComponent, {
      width: '60%',
      header: this.translocoService.translate(
        'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_CREATE_SUBMISSION_FORM_HEADER'
      ),
      data: { form: this.ddsFormGroup, editable, mode, rowData: this.rowData, geoJsonData: this.geoJsonData, tableParams: this.dialogConfig.data.tableParams },
    });
  }

  changeModeToAmendDDS() {
    this.editable = true;
    this.mode = this.ddsModalModesEnum.AMEND;
  }

  closeModal() {
    this.dialogRef.close();
  }

  ammendDDS() {
    //insert backend integration of updating DDS
    this.closeModal();
    this.messageService.add({
      severity: 'success',
      detail: 'DDS submission for SI/0123/0010 has been ammended successfully'
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventStateService } from '../state-service/event-state.service';
import { DDSSubmissionPayload } from 'src/app/models/dds-submission-payload.model';

@Injectable({
  providedIn: 'root',
})
export class EuisService {
  constructor(
    private http: HttpClient,
    private eventStateService: EventStateService,
  ) { }

  getEuIsAdapterList(
    sidetail_id: number[]
  ) {
    this.eventStateService.isEuisAdapterListLoading = true;
    const sidetailIdsParam = sidetail_id.join(',');
    return this.http
      .get<any>(
        `${environment.TRACEABILITY_API_v1}euis/adaptor/?foreign_keys=${sidetailIdsParam}`
      ).pipe(
        finalize(() => {
          this.eventStateService.isEuisAdapterListLoading = false;
          this.eventStateService.isTraceabilityTableLoading = false;
        })
      );
  }

  getHsCodes() {
    return this.http
      .get<any>(
        `${environment.TRACEABILITY_API_v1}euis/adaptor/hscodes/`
      ); 
  }

  submitDDSToEuIs(siDetailId: number, payload: DDSSubmissionPayload, override: boolean): Observable<any> {
    return this.http.post(
      `${environment.TRACEABILITY_API_v1}euis/adaptor/submit/?foreign_key=${siDetailId}&override=${override}`,
      payload
    );
  }

  withdrawDDSFromEuIs(payload: any): Observable<any> {
    return this.http.post(
      `${environment.TRACEABILITY_API_v1}euis/adaptor/retract/`,
      payload
    );
  }

  fetchEuIsData(payload: any): Observable<any> {
    return this.http.post(
      `${environment.TRACEABILITY_API_v1}euis/adaptor/fetch/`,
      payload
    );
  }
}

<div *ngIf="showTableHeader" class="grid">
  <div class="col-12">
    <p-card *ngIf="
        (traceabilityStateService.pagedTraceabilityData$ | async) !==
          INITIAL_PAGED_DATA_STATE &&
          (eventStateService.isSettingsLoaded$ | async);
        else controlsLoading
      ">
      <div class="flex justify-content-between">
        <div class="flex flex-column p-2 mr-3 w-100 selected-si-length">
          <span>{{ selectedItems.length }}
            {{ "DASHBOARD.SELECTED" | transloco }}</span>
        </div>

        <div class="flex justify-content-end selection-controls">
          <p-button [disabled]="isListControlsDisabled" styleClass="p-button-text m-1" [style]="{ 'min-width': '7rem' }"
            [text]="true" (onClick)="onClearAllClicked()">{{ "DASHBOARD.CLEAR_ALL" | transloco }}</p-button>
          
            <ng-container *ngIf="!isTraderLinkingTRN" >
              <p-button [disabled]="isListControlsDisabled" styleClass="p-button m-1" [style]="{ 'min-width': '9rem' }"
              (onClick)="onShowMap()">
              <i class="pi pi-map mr-2"></i>{{ "DASHBOARD.SHOW_MAP" | transloco }}</p-button>
              <!-- Temporary comment out -->
              <p-button [disabled]="selectedItems.length !== 1" styleClass="secondary-button p-button m-1"
                [style]="{ 'min-width': '9rem' }" (onClick)="onGenerateDdsClicked()">
                {{ "DASHBOARD.GENERATE_DDR" | transloco }}</p-button>
              <p-button [disabled]="!dopEnabled" styleClass="secondary-button p-button m-1"
                [style]="{ 'min-width': '9rem' }" (onClick)="onGenerateDopClicked()"> 
                {{ "DASHBOARD.GENERATE_DOP" | transloco }}</p-button>
              <p-button *ngIf="isTrader && (userStateService.userViewAs$ | async) === CompanyTypesEnum.Consumer" [disabled]="!enableTraderShareOrCombine" styleClass="secondary-button p-button m-1"
                [style]="{ 'min-width': '9rem' }" (onClick)="onLinkToConsumersTRN()"> 
                {{ "DASHBOARD.TRADER_TRACEABILITY.COMBINE_SHARE" | transloco }}</p-button>
                <p-button [disabled]="(publishTrnDisabled | async) ?? true" styleClass="secondary-button p-button m-1"
                [style]="{ 'min-width': '6rem' }" (onClick)="publishTRNs()">
                {{ "DASHBOARD.PUBLISH" | transloco }}</p-button>
              <!-- <div class="mx-1"> -->
              <!-- <app-request-audit-button
                [isListControlsDisabled]="isListControlsDisabled"
                (requestAuditClicked)="onRequestAudit()"
              ></app-request-audit-button> -->
              <!-- </div> -->
    
              <p-button [disabled]="isListControlsDisabled" styleClass="m-1" [outlined]="true"
                (click)="downloadOptions.toggle($event)">
                <i class="pi pi-download mr-2"></i>
                {{ "DASHBOARD.DOWNLOAD" | transloco }}</p-button>
              <p-overlayPanel #downloadOptions>
                <div class="flex flex-column download-options">
                  <div *ngFor="let option of downloadOptionProps; last as last"
                    class="flex align-items-center download-option" [ngClass]="{
                      disabled:
                        (option.loadingState | async) ||
                        (option.hasDisabledCondition && isEudrPackageDisabled),
                    }" (click)="onDownloadClicked(option.downloadOption)">
                    <div>
                      <i class="pi pi-download mr-3"></i>
                    </div>
                    <div class="mr-2">
                      <span> {{ option.label }}</span>
                    </div>
                    <p-progressSpinner *ngIf="option.loadingState | async" strokeWidth="8"
                      styleClass="w-2rem h-2rem mr-2"></p-progressSpinner>
                  </div>
                </div>
              </p-overlayPanel>
            </ng-container>          
        </div>
      </div>
      <div class="flex chips-container align-items-center mt-2" *ngIf="selectedItems.length">
        <p-chips (keypress)="(false)" (onRemove)="onSelectedItemRemove($event.value)"
          [(ngModel)]="selectedItemNames"></p-chips>
      </div>
    </p-card>
  </div>
</div>

<div class="grid mt-2" *ngIf="traderStateService.selectedScreen() !== TraderLinkScreenEnum.CONFIRMATION_DETAILS">
  <div class="col-12 traceability-table">
    <ng-container *ngIf="
        (traceabilityStateService.pagedTraceabilityData$ | async) ===
          INITIAL_PAGED_DATA_STATE ||
        !(eventStateService.isSettingsLoaded$ | async)
      ">
      <ng-template *ngTemplateOutlet="tableLoading"></ng-template>
    </ng-container>
    <p-card [class.hidden]="
        (traceabilityStateService.pagedTraceabilityData$ | async) ===
          INITIAL_PAGED_DATA_STATE ||
        !(eventStateService.isSettingsLoaded$ | async) || (selectedTab === 1 && !isUnlocked )
      ">
      <p-progressBar class="progress-bar" *ngIf="((eventStateService.isTraceabilityTableLoading$ | async)! ||
      !(eventStateService.isSiSummariesLoaded$ | async)! ||
      (((eventStateService.isEuisAdapterListLoading$ | async) ?? false) && !secretKey)) &&
    !(eventStateService.error$ | async)" mode="indeterminate"></p-progressBar>

      <p-table #dt [value]="rowData" [paginator]="rowData ? true : false" [rows]="pageRows"
        [(selection)]="selectedItems" [totalRecords]="totalRecords"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" (onLazyLoad)="lazyLoadTraceabilityTable($event)"
        [lazy]="traderStateService.selectedScreen() !== TraderLinkScreenEnum.SUMMARY" [columns]="columns" [dataKey]="tableDataKey"
        [tableStyle]="selectedTab !== 1 ? { 'min-width': '150rem' } : {}" [selectionPageOnly]="true"
        (selectionChange)="onSelectionChange($event)" [selectionMode]="'multiple'" [showCurrentPageReport]="true"
        [scrollable]="true"
        [loading]="
          ((eventStateService.isTraceabilityTableLoading$ | async)! ||
            !(eventStateService.isSiSummariesLoaded$ | async)! ||
            ((eventStateService.isTraderCopyingInProgress$ | async) ?? false) ||
            (((eventStateService.isEuisAdapterListLoading$ | async) ?? false) && !secretKey)) &&
          !(eventStateService.error$ | async) 
        " currentPageReportTemplate="{{
          'DASHBOARD.SHOWING_TABLE_ENTRIES_COUNT'
            | transloco
              : {
                  first: '{first}',
                  last: '{last}',
                  totalRecords: '{totalRecords}'
                }
        }}" [globalFilterFields]="globalFilterFields" [rowsPerPageOptions]="[10, 30, 100]"
        [sortField]="sortProps.sortField" [sortOrder]="sortProps.sortOrder">
        <ng-template pTemplate="caption">
          <div class="grid p-2 mt-0 header-table">
            <div class="col-12 flex align-items-center justify-content-between">
              <div class="flex table-header">
                <div class="table-label mr-4">
                  <span *ngIf="isTraderLinkingTRN && traderStateService.selectedScreen() === TraderLinkScreenEnum.SUMMARY" class="text-primary mr-2">{{ selectedItems.length }}</span> 
                  <span>{{ tableTitle }}</span>
                </div>
                <!-- <p-dropdown
                  [options]="tableViewOptions"
                  [(ngModel)]="selectedTableView"
                  (onChange)="onSelectedTableViewChange()"
                  optionLabel="label"
                  optionValue="value"
                ></p-dropdown> -->
              </div>
              <div>
                <span *ngIf="traderStateService.selectedScreen() !== TraderLinkScreenEnum.SUMMARY" class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input type="text" (input)="applyFilterGlobal($event, 'contains')" class="search-bar" pInputText
                    placeholder="{{ 'DASHBOARD.SEARCH' | transloco }}" />
                </span>
                <!-- <p-button
                  (onClick)="onUploadClicked()"
                  [disabled]="selectedItems.length ? true : false"
                  [outlined]="true"
                  styleClass="mx-2"
                >
                  <i class="pi pi-upload mr-2"></i>Upload</p-button
                > -->
                <ng-container *ngIf="!isTraderLinkingTRN">
                  <ng-container *ngTemplateOutlet="createNewSiBtn"></ng-container>
                </ng-container>
              </div>


            </div>
            <ng-container *ngIf="!isTraderLinkingTRN">
              <div class="w-full divider-container">
                <p-divider type="solid" />
              </div>
              <div class="col-12 py-0 mb-2-5">
                <p-tabView [activeIndex]="selectedTab" (activeIndexChange)="onTabChange($event)"
                  class="traceability-tabs">
                  <p-tabPanel header="{{ 'DASHBOARD.TRACEABILITY_TABLE' | transloco }}">
                  </p-tabPanel>
                  <p-tabPanel>
                    <ng-template pTemplate="header">
                      <span class="pi pr-2" [ngClass]="{'pi-lock': !isUnlocked, 'pi-unlock': isUnlocked}"></span>
                      {{ 'DASHBOARD.EU_IS_TABLE' | transloco }}
                    </ng-template>
                  </p-tabPanel>
                </p-tabView>
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th class="checkbox-cell" *ngIf="!(isTraderLinkingTRN && traderStateService.selectedScreen() === TraderLinkScreenEnum.SUMMARY)">
              <p-tableHeaderCheckbox #headerCheckbox></p-tableHeaderCheckbox>
            </th>
            <ng-container *ngFor="let column of columns; index as i">
              <th [style]="column?.style || ''" [ngClass]="getFilterClass(selectedFilterValues[column.field])"
                [pSortableColumn]="column.sortable ? column.field : ''">
                <div class="th-container">
                  <label>
                    {{ column.displayName | transloco }}
                  </label>

                  <p-sortIcon *ngIf="column.sortable" [field]="column.field"></p-sortIcon>
                  <!-- TODO: ask BE to align filter name and fields -->
                  <p-columnFilter *ngIf="
                      (getFilterOptions(column.filterName!).length > 0 ||
                      column.filter)
                    " [field]="column.field" matchMode="in" display="menu" [showMatchModes]="false"
                    [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <p-multiSelect *ngIf="column.field !== 'has_plantation_data' && column.field !== 'publish_status'"
                        [ngModel]="selectedFilterValues[column.field]" [options]="getFilterOptions(column.filterName!)"
                        placeholder="{{ 'DASHBOARD.ANY' | transloco }}" optionLabel="label" optionValue="value"
                        [filter]="true" (ngModelChange)="
                          onFilterChange(column.field, $event, filter)
                        ">
                        <ng-template let-option pTemplate="item">
                          <span class="ml-1 mt-1">{{ option.label }} </span>
                        </ng-template>
                      </p-multiSelect>

                      <p-dropdown *ngIf="column.field === 'has_plantation_data' || column.field === 'publish_status'"
                        [ngModel]="selectedFilterValues[column.field]" [options]="getFilterOptions(column.filterName!)"
                        (ngModelChange)="
                          onFilterChange(column.field, $event, filter, true)
                        " placeholder="{{ 'DASHBOARD.ANY' | transloco }}">
                        <ng-template let-option pTemplate="item">
                          <span class="ml-1 mt-1">{{ option.label }} </span>
                        </ng-template>
                      </p-dropdown>
                      <!-- TODO: refactor - align to crd implementation -->
                      <p-button (onClick)="
                          onClearFilter(column.field, column.filterName!);
                          hideFilterOverlay()
                        " [outlined]="true" styleClass="clear-btn">
                        {{ "DASHBOARD.CLEAR" | transloco }}
                      </p-button>
                      <p-button (onClick)="filter(value); hideFilterOverlay()" [disabled]="
                          selectedFilterValues[column.field]?.length <= 0
                        " [outlined]="true" styleClass="apply-btn">
                        {{ "DASHBOARD.APPLY" | transloco }}
                      </p-button>
                    </ng-template>
                  </p-columnFilter>
                </div>
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-index="rowIndex">
          <tr [pEditableRow]="rowData" *ngIf="rowData && euisAdapterLoaded">
            <td class="checkbox-cell"  *ngIf="!(isTraderLinkingTRN && traderStateService.selectedScreen() === TraderLinkScreenEnum.SUMMARY)">
              <p-tableCheckbox 
                [disabled]="hasMissingOrPendingPlantation(rowData)" 
                [value]="hasMissingOrPendingPlantation(rowData) ? false : rowData"
                [pTooltip]="plantationErrorMessage(rowData)"
                tooltipPosition="top"
                tooltipEvent="hover">
              </p-tableCheckbox>
            </td>
            <ng-container *ngFor="let column of columns; index as i">
              <td>
                <ng-container *ngIf="
                    column.field !== 'has_plantation_data' &&
                    column.field !== 'has_eudr_package' &&
                    column.field !== 'risks' &&
                    column.field !== 'action_column' &&
                    column.field !== 'eudr_package' &&
                    column.field !== 'dds_submission_euis' && 
                    column.field !== 'submitted_on' && 
                    column.field !== 'has_ddr' &&
                    column.field !== 'publish_status' &&
                    column.field !== 'created_at' &&
                    column.field !== 'si_weight'
                  ">
                  <label>{{ rowData[column.field] || "-" }}</label>
                </ng-container>
                <ng-container *ngIf="column.field === 'risks'">
                  <ng-container *ngIf="rowData['risks']?.length; else noData">
                    <ng-container *ngFor="let risk of rowData['risks']">
                      <app-risk-icon [riskType]="risk" [marginClass]="'mr-2'" [hasTooltip]="true"></app-risk-icon>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="column.field === 'submitted_on'">
                  <span class="mr-1">
                    {{ rowData['submitted_on'] ? (rowData['submitted_on'] | date:'MM/dd/yyyy h:mm a') : '-' }}
                  </span>
                </ng-container>
                <ng-container *ngIf="column.field === 'has_plantation_data'">
                  <label class="flex align-items-center" *ngIf="!rowData[column.field]; else showCheckIcon">
                    <span class="material-symbols-outlined warning-icon mr-1" style="color: var(--primary-color)">
                      warning
                    </span>
                    <span (click)="onLinkPlantationClicked(rowData)" class="link-plantation">{{
                      "DASHBOARD.SI_LINKING.LINK_PLANTATION_TO_SI" | transloco
                      }}
                    </span>
                  </label>
                </ng-container>
                <!-- <ng-container *ngIf="column.field === 'has_eudr_package'">
                  <ng-container *ngIf="rowData[column.field]; else noData">
                    <ng-template *ngTemplateOutlet="showCheckIcon"></ng-template>
                  </ng-container>
                </ng-container> -->
                <ng-container *ngIf="column.field === 'si_weight'">
                  <ng-container *ngIf="rowData[column.field]; else noData">
                    <span>{{ rowData[column.field] | number : '1.2-2'}}</span>
                  </ng-container>
                </ng-container>
                 <ng-container *ngIf="column.field === 'created_at'">
                  <ng-container *ngIf="rowData[column.field]; else noData">
                    <span>{{formatDate(rowData[column.field], true)}}</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="column.field === 'dds_submission_euis'">
                  <ng-container>
                    <span class="icon-button material-symbols-outlined" *ngIf="!rowData['status']|| rowData['status'] === 'Failed'"
                      (click)="onDdsSubmissionForm(rowData, true, DDSModalModesEnum.SUBMIT)"> add_notes</span>
                    <span class="icon-button material-symbols-outlined" *ngIf="rowData['status'] && rowData['status'] === 'Submitted'"
                      (click)="onDdsSubmissionForm(rowData, false, DDSModalModesEnum.VIEW)"> article</span>
                    <!-- <span class="icon-button material-symbols-outlined" (click)="onUploadEudrPackage(rowData)"> {{
                      'download' }} </span> -->
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="column.field === 'publish_status'">
                  <ng-container *ngIf="rowData['publish_status']; else noData">
                    <div class="flex justify-content-between">
                      <p-chip 
                        [label]="transformStatus(rowData['publish_status'])"
                        [ngClass]="{'chip-green': rowData['publish_status'] === 'PUBLISHED', 'chip-red': rowData['publish_status'] === 'DELETED',  'chip-gray': rowData['publish_status'] === 'UNPUBLISHED'}"
                      />
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="column.field === 'action_column'">
                  <div class="flex justify-content-between">
                    <!-- <span class="material-symbols-outlined cursor-pointer text-primary"> edit </span> -->
                    <span class="material-symbols-outlined cursor-pointer text-primary email-btn"
                      [ngClass]="{ 'disabled': !(rowData['has_eudr_package'] || rowData['has_plantation_data']) || rowData?.summary?.error === plantationProcessing }"
                      (click)="rowData['has_eudr_package'] || rowData['has_plantation_data'] ? onEmailToCounterparty(rowData) : null"
                      pTooltip="{{ 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.EMAIL_TOOLTIP' | transloco }}">
                      mail
                    </span>
                    <!-- <span class="material-symbols-outlined cursor-pointer text-red-500" (click)="modifySiDetails(rowData)"> delete</span> -->
                    <span class="icon-button material-symbols-outlined cursor-pointer text-red-500"
                    [ngClass]="{ 'disabled': notUserCreated(rowData)}"
                    (click)="deleteTRN(rowData)"> delete </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="column.field === 'eudr_package'">
                  <ng-container *ngIf="rowData['has_eudr_package']; else uploadIcon">
                    <span class="icon-button material-symbols-outlined"
                      (click)="onUploadEudrPackage(rowData, rowData['has_eudr_package'], notUserCreated(rowData))">
                      description
                    </span>
                  </ng-container>
                  <ng-template #uploadIcon>
                    <img src="assets/images/upload_file.svg" alt="Upload File" class="icon-button outlined-icon"
                    (click)="onUploadEudrPackage(rowData, rowData['has_eudr_package'], notUserCreated(rowData))"
                      width="24" height="24" />
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="column.field === 'has_ddr'">
                  <ng-container *ngIf="rowData['has_ddr']; else noData">
                    <div class="flex align-items-center">
                      <span class="icon-button material-symbols-outlined" style="color: var(--primary-color)"
                        (click)="downloadDdrPdf(rowData)">
                        download
                      </span>
                      <p-progressSpinner *ngIf="pdfLoading" styleClass="small-spinner"
                        strokeWidth="4"></p-progressSpinner>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #showCheckIcon>
                  <i class="pi pi-check"></i>
                </ng-template>
              </td>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="11">
              <span style="color: c6c6d0">No results found</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>
</div>

<app-create-new-si-modal *ngIf="traderStateService.selectedScreen() === TraderLinkScreenEnum.CONFIRMATION_DETAILS"></app-create-new-si-modal>

<div class="flex flex-wrap gap-3 mt-2 justify-content-end" *ngIf="isTraderLinkingTRN">
  <p-button styleClass="bg-white text-primary" (click)="onCancelTraderLink()">{{ 'DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.BACK_BTN' | transloco }}</p-button>
  <ng-container *ngIf="traderStateService.selectedScreen() === TraderLinkScreenEnum.SELECT_TRADE" [ngTemplateOutlet]="createNewSiBtn"></ng-container>
  
  <p-button 
    [disabled]="disableTraderNextAction" 
    (click)="onConfirmTraderLink()">
      {{ 
        traderStateService.selectedScreen() !== TraderLinkScreenEnum.SUMMARY ?
          ('DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.NEXT_BTN' | transloco) : 
          ('DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.CONFIRM_BTN' | transloco) 
      }}
  </p-button>
</div>

<p-confirmDialog key="delete-trn-modal" class="alert-confirm-modal"></p-confirmDialog>
<p-confirmDialog key="publish-modal" class="alert-confirm-modal"></p-confirmDialog>

<ng-template #tableLoading>
  <p-skeleton width="100%" height="50vh"></p-skeleton>
</ng-template>

<ng-template #controlsLoading>
  <p-skeleton width="100%" height="10vh"></p-skeleton>
</ng-template>

<ng-template #noData><span class="ml-2">-</span></ng-template>

<ng-template #createNewSiBtn>
  <p-button 
    [disabled]="selectedItems.length ? true : false" 
    (click)="createNewSiOptions.toggle($event)">
    <i class="pi pi-plus mr-2"></i>
    {{ "DASHBOARD.CREATE_NEW_SI.CREATE_NEW_SI" | transloco }}
  </p-button>
  <p-overlayPanel
    #createNewSiOptions 
    styleClass="mt-3-2" 
    class="hidden">
    <div class="flex flex-column download-options">
      <div 
        *ngFor="let option of createNewSiOptionProps; last as last" 
        class="flex align-items-center download-option"
        (click)="onCreateNewSiClicked(option.option, createNewSiOptions);">
        <div class="mr-2">
          <span> {{ option.label }}</span>
        </div>
      </div>
    </div>
  </p-overlayPanel>
</ng-template>
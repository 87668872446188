import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-add-dynamic-control-fields',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslocoPipe
  ],
  templateUrl: './add-dynamic-control-fields.component.html',
  styleUrls: ['./add-dynamic-control-fields.component.scss']
})
export class AddDynamicControlFieldsComponent {
  /**
   * Input variables
   */
  @Input() form?: FormGroup | null = null;
  @Input() confirmed = false;

  /**
   * Output variables
   */

  @Output() removeControl = new EventEmitter();

}

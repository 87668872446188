<div class="flex flex-column mx-4">
    <div class="header-background font-semibold">
        <span>{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.EU_IS_USER_MODAL.EU_IS_USER_MODAL_SUBHEADER" | transloco }}</span>
    </div>
    <div class="my-4">
        <form [formGroup]="credentialsForm">
            <div class="flex flex-column">
                <label for="username">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.EU_IS_USER_MODAL.USERNAME" | transloco }}</label>
                <input id="username" type="text" pInputText formControlName="username" placeholder="{{ 'DASHBOARD.EU_IS_TABLE_COLUMNS.EU_IS_CREDENTIALS_NAME' | transloco}}" />
            </div>
            <div class="flex flex-column mt-3">
                <label for="passkey">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.EU_IS_USER_MODAL.PASSKEY" | transloco }}</label>
                <input id="passkey" type="password" pInputText formControlName="passkey" placeholder="{{ 'DASHBOARD.EU_IS_TABLE_COLUMNS.EU_IS_CREDENTIALS_PASS' | transloco}}" />
            </div>
        </form>
    </div>
    <div class="flex justify-content-end modal-action-section">
        <p-button class="mr-4 mt-4" [outlined]="true" (click)="onCancel()">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.EU_IS_USER_MODAL.CANCEL" | transloco }}</p-button>
        <p-button class="mr-4 mt-4" (click)="onProceed()"
        [disabled]="!credentialsForm.valid">{{ "DASHBOARD.EU_IS_TABLE_COLUMNS.EU_IS_USER_MODAL.PROCEED" | transloco }}</p-button>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { UtilityService } from 'src/app/services/utility.service';
import { Subject, takeUntil } from 'rxjs';
import { PostMessageTypesEnum } from 'src/app/enums/post-message-types.enum';
import { UserInfoStateService } from 'src/app/services/state-service/user-info-state.service';
import { UserViewAsSetting } from 'src/app/models/user-info-state.model';

@Component({
  selector: 'app-link-plantation-modal',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule, TranslocoPipe],
  templateUrl: './link-plantation-modal.component.html',
  styleUrls: ['./link-plantation-modal.component.scss'],
})
export class LinkPlantationModalComponent implements OnInit {
  destroyed$ = new Subject<void>();
  company!: { type?: string; name?: string };
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private utilityService: UtilityService,
    private userInfoStateService: UserInfoStateService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
  }

  getUserInfo() {
    this.userInfoStateService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userInfo) => {
        this.company = {
          type: userInfo?.company?.type,
          name: userInfo?.company?.name,
        };
      });
  }

  onRedirectToPmdClicked() {
    let rowData = this.dialogConfig.data?.rowData;
    rowData.company = this.company;
    const viewSettings = this.utilityService.retrieveFromObservable(this.userInfoStateService.viewAsSetting$) as UserViewAsSetting;
    if (viewSettings?.view !== null && viewSettings?.view !== undefined) {
      rowData.defaultMode = viewSettings.view.toString();
    }
    this.utilityService.emitPropToParent(
      'sidetails',
      rowData,
      'type',
      PostMessageTypesEnum.REDIRECT_PMD
    );
  }
}

export const environment = {
  production: false,
  isCn: false,
  mapboxToken:
    'pk.eyJ1IjoiYnJ5YW5oYyIsImEiOiJjbG5lN2hhd3IwZHA0MnNtamFvenpqMjR5In0.v6IJPRIgSL1cjD2WMI75VA',
  TRACEABILITY_API:
    'https://traceability-development.agridence.com/api/v2/traceability/service/',
  TRACEABILITY_API_v1:
    'https://traceability-development.agridence.com/api/v1/traceability/service/',
  TRACEABILITY_INTEGRATIONS_API:
    'https://traceability-development.agridence.com/api/v1/traceability-integrations/',
  TRACEABILITY_EXTERNAL:
    'https://traceability-development.agridence.com/_external/v2/traceability/external/si/',
  ARP_API: 'https://api-platform-testing.agridence.com/api/v1/',
  CRD_API: 'https://api-traceability-crd-development.agridence.com/api/',
  TRACEABILITY_API_CN:
    'https://traceability-development.agridencecn.com/api/v2/traceability/service/',
    TRACEABILITY_API_v1_CN:
      'https://traceability-development.agridencecn.com/api/v1/traceability/service/',
  TRACEABILITY_INTEGRATIONS_API_CN:
    'https://traceability-development.agridencecn.com/api/v1/traceability-integrations/',
  TRACEABILITY_EXTERNAL_CN:
    'https://traceability-development.agridencecn.com/_external/v2/traceability/external/si/',
  ARP_API_CN: 'https://api-platform-testing.agridencecn.com/api/v1/',
  CRD_API_CN: 'https://api-traceability-crd-development.agridencecn.com/api/',
  USER_CONFIG:
    'https://assets.agridence.com/configs/development/{tenantId}/config.json',
    STORAGE_BUCKET:'traceability/eudr-packages/{siNumber}/',
  STORAGE_BUCKET_V2:'traceability/eudr-packages-v2/{siNumber}',
  firebase: {
    apiKey: "AIzaSyCCefSqEqw5Vga5YlItyd9vpzmhSlqgZ-0",
    authDomain: "hcplatform-development.firebaseapp.com",
    projectId: "hcplatform-development",
    storageBucket: "gs://agd-file-uploads-development",
    messagingSenderId: "612736903809",
    appId: "1:612736903809:web:74a4ebadab4b59004afb80",
    measurementId: "G-K2DSKHCGTC"
  },
};

import { GeoJSONGeometryOrNull } from 'wellknown';
import { RISK_DESCRTIPTIONS } from '../constants/risk-description.const';
import { RiskTypesEnum } from '../enums/risk-types.enum';
import { ScreenEnum, TraderLinkScreenEnum } from '../enums/screens.enum';
import { PagedResponse } from './paged-response.model';
import { GeometryPayload } from './geometry-payload.model';
import { PlantationTableFilters } from './table-params.model';
import { TraceabilityTableTabsEnum } from '../enums/traceability-table-tabs.enum';

export interface TraceabilityData {
  sidetail_id?: number;
  si_number?: string;
  polygon_data?: string | null;
  contract_number?: string;
  counterparty?: string;
  created_at?: string;
  buyer_ref_numb?: string;
  po_number?: string;
  grade_name?: string;
  volume?: string;
  factory_code?: string;
  si_weight?: number;
  delivery_datetime?: string;
  destination?: string;
  extent_traceability?: string;
  contract_buyer_nm?: string;
  producer?: string;
  // country data set
  country?: string;
  total_volume?: string;
  traced_to_district?: string;
  traced_to_collection_point?: string;
  traced_to_plantation?: string;
  untraced?: string;
  checked?: boolean;
  isExpanded?: boolean;
  risks?: any;
  summary?: SiSummary;
  has_plantation_data?: boolean;
  plantationData?: PlantationsBySi[];
  has_eudr_package?: boolean;
  has_ddr?: boolean;

  id?: number;
  foreign_key?: string;
  service?: string;
  dds_identifier?: string | null;
  dds_reference_number?: string | null;
  dds_verification_number?: string | null;
  status?: string;
  submitted_on?: string;
  amended_on?: string;
  retracted_on?: string;
  submission_payload_url_json?: string;
  submission_payload_url_xml?: string;
  submission_result_response_url_json?: string;
  submission_result_response_url_xml?: string;
  publish_status?: string;
  created_by_user_id?: number;
}

export interface Destination {
  id: string;
  destination_country: string;
  name: string;
}

export interface TradeConfirmationData {
  contract_number: string;
  id: number;
  type: string;
  price: string;
  volume: string;
  factory: {
    factory_code: string;
  };
  delivery_date: string;
  grade: { grade_code: string };
  producer: { id: number; name: string };
  buyer: { name: string };
  consumer: { name: string };
  counterparty?: string;
  destination?: Destination 
}

export interface CounterpartiesData {
  id: number;
  name: string;
  type: string;
}

export interface SiSummary {
  risks: any;
  si_numb: string;
  total_area: number;
  total_plantations: number;
  total_points: number;
  total_polygons: number;
  error?: any;
  countries: string[];
}

export interface RiskOverlapDetail {
  totalAreas: string;
  areas: any[];
  label: string;
  riskType: string;
  description?: any;
}

export interface TableFilters {
  [key: string]: {
    default_option: string | null;
    options: string[];
    multiple: boolean;
  };
}

export interface LinkPlantationData {
  _id?: string;
  plantation_code: string;
  plantation_name: string;
  area_calculated: string;
  country: string;
  adm_1: string;
  adm_2: string;
  date_created: string;
  data_source: string;
  field_team_code?: string;
  _geo?: string;
  risks?: any[];
  isExpanded?: boolean;
  checked?: boolean;
  mapData?: MapData;
  has_any_risk_value?: boolean;
  T?: string;
  // trandaction id data set
  id?: string;
  sellerName?: string;
  transactionDate?: string;
  dryWeightKg?: string;
}

export interface PlantationRisk {
  name: string;
  display_name: string;
  color: string;
  type: string;
  is_default?: boolean;
  description: string;
}

export interface RiskLayer {
  attribution: string;
  dataurl: string;
  id: string;
  links: string[];
  mapid: string;
  name: string;
  provider_link: string;
  provider_name: string;
  token: string;
  tou: string;
  tou_link: string;
  type: string;
  checked?: boolean;
  color: string;
}

export interface PlantationDataset {
  name: string;
  default_plantations_table: string;
  label?: string;
  description?: string;
}

export interface Plantation {
  plantation_code: string;
  plantation_name: string;
  area_calculated: number;
  country: string;
  adm_1: string;
  adm_2: string;
  date_created: string;
  data_source: string;
  field_team_code?: string;
  _geo?: string;
  risks?: any[];
  isExpanded?: boolean;
  checked?: boolean;
  mapData?: MapData;
}

export interface MapData {
  type: string;
  properties: any[];
  coordinates?: any;
  geometry: GeometryProps;
}

export interface GeometryProps {
  type: string;
  coordinates: any;
}

export interface PlantationGeometryData {
  plantation_code: string;
  _geo: MapData | string;
}

export interface TransactionDataset {
  factory: { id: number; name: string };
  factory_integration_provider: { display_name: string; name: string };
  destination_factory_code: string;
  label?: string;
}

export interface PlantationsBySi {
  plantation_code: string;
  source: string;
  plantation_name: string;
  country: string;
  adm1: string | null;
  adm2: string | null;
  adm3: string | null;
  adm4: string | null;
  needs_risk_calculation: boolean;
  needs_adm_calculation: boolean;
  risks: any | null;
  risks_updated_on: Date | null;
  _geo: string | GeoJSONGeometryOrNull;
  si?: string[] | string;
}

export interface CompositionData {
  id: number;
  parent_id: number | null;
  top_adm_detail_id: number;
  adm_level: number;
  adm_name: string;
  si_numb: string;
  composition_prct: number;
  created_at: string;
  last_modified_at: string;
  isExpanded?: boolean;
}

export interface Mapping {
  si_page?: {
    si_number: string;
    buyer_ref_numb: string;
  };
}

export interface SymbolOptions {
  id: number;
  name: string;
  factory_code: string;
  country: string;
}

export interface GradeOptions {
  id: number;
  name: string;
  grade_code: string;
  country: string;
  image: any;
}

// export interface

export interface TraceabilityState {
  pagedTraceabilityData: PagedResponse<TraceabilityData> | null;
  pagedTradeConfirmationData: PagedResponse<TradeConfirmationData> | null;
  pagedCounterpartiesData: PagedResponse<CounterpartiesData> | null;
  pagedLinkPlantationData: PagedResponse<LinkPlantationData>;
  siPageFilters?: TableFilters;
  selectedTraceabilityData?: TraceabilityData[];
  checkedSelectedTraceabilityData: TraceabilityData[];
  siDetails: TraceabilityData | null;
  currentScreen: ScreenEnum;
  mapStyle: string;
  plantationRisks?: PlantationRisk[];
  riskOverlapDetails: RiskOverlapDetail[];
  plantationDatasets?: PlantationDataset[];
  transactionDatasets?: TransactionDataset[];
  riskLayers: RiskLayer[];
  plantationsBySi?: PlantationsBySi[];
  compositionData?: CompositionData[] | null;
  siSummaries?: SiSummary[];
  plantationGeometryData?: PlantationGeometryData[];
  symbolOptions?: SymbolOptions[];
  eudrFiles?: string;
  gradeOptions?: GradeOptions[];
  geometryData?: GeometryPayload[];
  plantationListFilters?: PlantationTableFilters[];
  selectedTab?: TraceabilityTableTabsEnum;
  title: string;
}

export const INITIAL_RISK_OVERLAP_DETAILS_STATE: RiskOverlapDetail[] = [
  {
    label: 'RISK_TYPES.TREE_COVER_LOSS',
    areas: [],
    totalAreas: '',
    riskType: RiskTypesEnum.TREE_COVER_LOSS,
    // TODO: refactor implementation
    description: RISK_DESCRTIPTIONS.find(
      (risk) => risk.riskType === RiskTypesEnum.TREE_COVER_LOSS
    ),
  },
  {
    label: 'RISK_TYPES.WDPA',
    areas: [],
    totalAreas: '',
    riskType: RiskTypesEnum.WDPA,
    description: RISK_DESCRTIPTIONS.find(
      (risk) => risk.riskType === RiskTypesEnum.WDPA
    ),
  },
  // TODO: Temporary comment out
  // {
  //   label: 'Plantation Overlap',
  //   areas: [],
  //   totalAreas: '',
  //   riskType: RiskTypesEnum.SELF_OVERLAP,
  //   description: RISK_DESCRTIPTIONS.find(
  //     (risk) => risk.riskType === RiskTypesEnum.SELF_OVERLAP
  //   ),
  // },
];

export const INITIAL_PAGED_DATA_STATE: PagedResponse<any> = {
  count: 0,
  next: '',
  previous: '',
  num_pages: 0,
  page: 0,
  page_size: 0,
  results: [],
};

export const INITIAL_TRACEABILITY_STATE: TraceabilityState = {
  pagedTraceabilityData: INITIAL_PAGED_DATA_STATE,
  pagedTradeConfirmationData: INITIAL_PAGED_DATA_STATE,
  pagedCounterpartiesData: INITIAL_PAGED_DATA_STATE,
  pagedLinkPlantationData: INITIAL_PAGED_DATA_STATE,
  siDetails: {},
  mapStyle: '',
  // selectedTraceabilityData: [],
  checkedSelectedTraceabilityData: [],
  currentScreen: ScreenEnum.DASHBOARD,
  riskOverlapDetails: INITIAL_RISK_OVERLAP_DETAILS_STATE,
  riskLayers: [],
  title: 'DASHBOARD.MY_TRACEABILITY'
  // plantationDatasets: [],
  // transactionDatasets: [],
};

<!-- <div class="grid">
  <div class="col-12">
    <div class="flex justify-content-between align-items-center mb-3">
      <div class="ml-5-5">
        <app-refresh-button
          [lastUpdatedDate]="'30/06/2023'"
        ></app-refresh-button>
      </div>
      <p-button [disabled]="true" styleClass="m-1">
        <i class="pi pi-download"></i
      ></p-button>
    </div>
  </div>
</div> -->
<div class="grid top-section">
  <div class="col-8-5">
    <p-card><app-map></app-map></p-card>
  </div>
  <div class="col-4-5">
    <p-card
      ><app-selected-si-details-summary></app-selected-si-details-summary
    ></p-card>
  </div>
</div>
<div class="grid mt-1">
  <div class="col-12">
    <app-risk-overlap-panel></app-risk-overlap-panel>
  </div>
</div>

<ng-template #polygonsLoading>
  <div class="progress-bar-skeleton">
    <p-progressBar mode="indeterminate"></p-progressBar>
    <p-skeleton width="15rem" height="7rem"> </p-skeleton>
  </div>
</ng-template>

<!-- Controls -->
<ng-container *ngIf="form">
    <div *ngIf="!confirmed; else confirmedTpl" class="sub-item delete-control">
        <ng-container [formGroup]="form">
            <input type="text" pInputText formControlName="title" placeholder="{{ 'DASHBOARD.DDR.DYNAMIC_FIELD.ENTER_NAME_PLACEHOLDER' | transloco }}" maxlength="250" />
            <div class="flex align-items-center justify-content-end">
                <input class="delete-control--value" type="text" pInputText formControlName="value" placeholder="{{ 'DASHBOARD.DDR.DYNAMIC_FIELD.ENTER_VALUE_PLACEHOLDER' | transloco }}" maxlength="250"/>
                <span class="pl-2 delete-control--icon font-bold material-symbols-outlined" alt="Remove Field" (click)="removeControl.emit()">delete</span>
            </div>
        </ng-container>
    </div>
</ng-container>

<!-- Templates -->
<ng-template #confirmedTpl>
    <div class="sub-item">
        <span class="w-50">{{ form?.value?.title }}</span>
        <span class="w-50 text-right">{{ form?.value?.value }}</span>
    </div>
</ng-template>
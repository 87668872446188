import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { last, map, switchMap, tap } from 'rxjs';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-upload-eudr-package-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    TranslocoPipe,
    ConfirmDialogModule
  ],
  templateUrl: './upload-eudr-package-modal.component.html',
  styleUrls: ['./upload-eudr-package-modal.component.scss']
})
export class UploadEudrPackageModalComponent implements OnInit, OnDestroy {
  uploadedFiles: any[] = [];
  siNumber: string = '';
  siDetailId: string = '';
  rowData: any;
  isLoading: boolean = false;
  isLoadingFiles: boolean = true;
  userType = '';
  authSubscription: any;
  tokenCheckInterval: any;
  hasEudrPackage = false;

  constructor(
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    public utilityService: UtilityService,
    private fireStorage: AngularFireStorage,
    private fireAuth: AngularFireAuth,
    public traceabilityStateService: TraceabilityStateService,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit() {
    this.rowData = this.dialogConfig.data?.rowData;
    this.userType = this.dialogConfig.data?.userType;
    this.hasEudrPackage = this.dialogConfig.data?.has_eudr_package;
    this.siNumber = this.rowData.producer === 'HG' ? this.rowData.si_number : this.rowData.sidetail_id;
    this.startTokenCheckInterval();
    this.checkAndSignInWithCustomToken();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.tokenCheckInterval) {
      clearInterval(this.tokenCheckInterval);
    }
  }

  checkAndSignInWithCustomToken() {
    const token = sessionStorage.getItem('Firebase_Token') || '';
    const expirationTime = localStorage.getItem('Firebase_Token_Expiration');
    const currentTime = new Date().getTime();

    if (!expirationTime || currentTime >= Number(expirationTime)) {
      this.signInWithCustomToken(token);
      this.startTokenCheckInterval();
    } else {
      if (this.uploadedFiles.length === 0) {
        this.getFiles();
      }
    }
  }

  signInWithCustomToken(token: string) {
    this.fireAuth.signInWithCustomToken(token).then(userCredential => {
      userCredential.user?.getIdTokenResult().then(idTokenResult => {
        const expirationTime = new Date(idTokenResult.expirationTime).getTime();
        localStorage.setItem('Firebase_Token_Expiration', expirationTime.toString());
        this.getFiles();
      });
    }).catch((error) => {
      console.error('Authentication error:', error);
    });
  }

  startTokenCheckInterval() {
    this.tokenCheckInterval = setInterval(() => {
      this.checkAndSignInWithCustomToken();
    }, 20 * 60 * 1000);
  }

  getFiles() {
    if (this.hasEudrPackage) {
      this.traceabilityStateService.getEudrFiles(this.rowData.si_number, this.rowData.sidetail_id).subscribe((files: any[]) => {
          this.uploadedFiles = files;
          this.isLoadingFiles = false;
      });
    } else {
      this.uploadedFiles = [];
      this.isLoadingFiles = false;
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.handleFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.target as HTMLElement).classList.add('drag-over');
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.target as HTMLElement).classList.remove('drag-over');
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.handleFiles(input.files);
    }
  }

  uploadFile(file: File, filePath: string): AngularFireUploadTask {
    const token = sessionStorage.getItem('Firebase_Token') || '';
    return this.fireStorage.upload(filePath, file, {
      customMetadata: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  handleFiles(files: FileList) {
    const token = sessionStorage.getItem('Firebase_Token') || '';

    const bucketPath = this.rowData.producer === 'HG'
      ? `${environment.STORAGE_BUCKET.replace('{siNumber}', this.siNumber)}`
      : `${environment.STORAGE_BUCKET_V2.replace('{siNumber}', this.siNumber)}`;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name;
      const filePath = `${bucketPath}/${fileName}`;

      this.uploadedFiles.push({ file, loading: true });

      const task = this.uploadFile(file, filePath);
      task.snapshotChanges().pipe(
        last(),
        switchMap(() => this.getDownloadLink(filePath))
      ).subscribe({
        next: (snapshot) => {
          if (!this.hasEudrPackage) {
            const payload = {
              si: this.rowData.si_number,
              sidetail_id: this.rowData.sidetail_id
            }
            this.traceabilityStateService.setEudrFiles(payload);
            this.hasEudrPackage = true;
          }
          this.uploadedFiles[this.uploadedFiles.length - 1].loading = false;
        },
        error: (error) => {
          console.error('Upload error:', error);
          this.utilityService.emitToast({
            message: 'There was an error uploading the file. Please try again.',
            isSuccess: false,
          });
          this.uploadedFiles.pop();
        }
      });
    }
  }

  getDownloadLink(filePath: string) {
    return this.fireStorage.ref(filePath).getDownloadURL().pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteFile(index: number) {
    const fileToDelete = this.uploadedFiles[index];
    const filePath = `${environment.STORAGE_BUCKET.replace('{siNumber}', this.siNumber)}/${fileToDelete.filename || fileToDelete.file.name}`;

    this.confirmationService.confirm({
      message: `${this.translocoService.translate('DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.DELETE_CONFIRMATION_MSG')} ${fileToDelete.filename || fileToDelete.file.name}?`,
      header: this.translocoService.translate('DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.DELETE_CONFIRMATION'),
      accept: () => {
        this.deleteFileFromFirebase(filePath).then(() => {
          this.utilityService.emitToast({
            message: 'File deleted successfully.',
            isSuccess: true,
          });
        });
        this.uploadedFiles.splice(index, 1);
      },
      reject: () => {
        console.log('Delete action cancelled');
      }
    });
  }

  async deleteFileFromFirebase(filePath: string): Promise<void> {
    const storageRef = this.fireStorage.ref(filePath);
    return storageRef.delete().toPromise().then(() => {
      console.log('File deleted successfully');
    }).catch((error) => {
      console.error('Error deleting file:', error);
    });
  }

  getFileSize(size: number): string {
    if (size < 1024) {
      return size + ' B';
    } else if (size < 1048576) {
      return (size / 1024).toFixed(2) + ' KB';
    } else if (size < 1073741824) {
      return (size / 1048576).toFixed(2) + ' MB';
    } else {
      return (size / 1073741824).toFixed(2) + ' GB';
    }
  }

  closeModal() {
    this.dialogRef.close()
  }
}
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';

import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-eu-is-credentials',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule, FormsModule, ReactiveFormsModule,
    TranslocoPipe],
  templateUrl: './eu-is-credentials.component.html',
  styleUrls: ['./eu-is-credentials.component.scss']
})
export class EuIsCredentialsComponent {
  isDialogVisible: boolean = true;
  credentialsForm: FormGroup;

  constructor(
    public ref: DynamicDialogRef,
    private fb: FormBuilder
  ) {
    this.credentialsForm = this.fb.group({
      username: ['', Validators.required],
      passkey: ['', Validators.required]
    });
  }

  onProceed() {
    if (this.credentialsForm.valid) {
      this.ref.close(this.credentialsForm.value);
    }
  }

  onCancel() {
    this.ref.close();
  }
}

<!-- Actions -->
<ng-content></ng-content>

<ng-container *ngIf="!confirmed">
    <div>
        <p-button 
            label="{{ 'DASHBOARD.DDR.DYNAMIC_FIELD.ADD_NEW_FIELD' | transloco }}"
            class="add-new-field"
            icon="pi pi-plus"
            [outlined]="true"
            [text]="true"
            [link]="true"
            (click)="newControl.emit(this.addNewControl())" />
    </div>
    <div *ngIf="addSection">
        <p-button 
            label="{{ 'DASHBOARD.DDR.DYNAMIC_FIELD.ADD_NEW_SECTION' | transloco }}"
            class="add-new-field"
            icon="pi pi-plus"
            [outlined]="true"
            (click)="newSection.emit(this.addNewControl())" />
    </div>
</ng-container>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddDynamicControlFormControl, AddDynamicControlFormControlGroup } from '../../interfaces';
import { ButtonModule } from 'primeng/button';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-add-dynamic-control',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
    TranslocoPipe
  ],
  templateUrl: './add-dynamic-control.component.html',
  styleUrls: ['./add-dynamic-control.component.scss']
})
export class AddDynamicControlComponent {

  /**
   * Input variables
   */
  @Input() confirmed = false;
  @Input() addSection = false;

  /**
   * Output variables
   */
  @Output() newControl = new EventEmitter<FormGroup<AddDynamicControlFormControl>>();
  @Output() newSection = new EventEmitter<FormGroup<AddDynamicControlFormControl>>();

  constructor(private fb: FormBuilder) { }

  addNewControl(): FormGroup<AddDynamicControlFormControl> {
    return this.fb.group({
      title: new FormControl<string | null>('', [Validators.required, Validators.maxLength(250)]),
      value: new FormControl<string | null>('', [Validators.required, Validators.maxLength(250)])
    });
  }
}

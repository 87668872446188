import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { INITIAL_SETTINGS_STATE, SettingsState } from 'src/app/models/settings-state.model';
import { DashboardService } from '../data-service/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsStateService extends StateService<SettingsState> {

  settings$ = this.select(state => state.settings);

  constructor(private dashboardService: DashboardService) {
    super(INITIAL_SETTINGS_STATE);
  }

  getSettings() {
    this.dashboardService
      .getSettings()
      .subscribe((res) => this.setState({ settings: res }));
  }
}

import { CompanyTypesEnum } from "../enums/company-types.enum";
import { CompanyTypeTableViewAsEnum } from "../enums/traceability-table-tabs.enum";

export interface UserInfo {
    company: { type: string; name: string };
}

export interface UserViewAsSetting {
    view: CompanyTypeTableViewAsEnum | null;
    param: { mode: string };
}

export interface UserInfoState {
    userInfo: UserInfo | null,
    userViewAs: CompanyTypesEnum | null,
    viewAsSetting: UserViewAsSetting | null
}

export const INITIAL_USERINFO_STATE: UserInfoState = {
    userInfo:  null,
    userViewAs: null,
    viewAsSetting: null
}
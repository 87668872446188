import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DdrHtmlPdfRequest } from 'src/app/models/ddr-download.model';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-dds-modal-template',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoPipe
  ],
  templateUrl: './dds-modal-template.component.html',
  styleUrls: ['./dds-modal-template.component.scss']
})
export class DdsModalTemplateComponent implements AfterViewInit {
  data!: DdrHtmlPdfRequest;
  @Output() ready = new EventEmitter();

  ngAfterViewInit(): void {
    this.ready.emit();
  }
}

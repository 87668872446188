import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { CompanyTypeTableViewAsEnum } from '../../enums/traceability-table-tabs.enum';
import { TabViewModule } from 'primeng/tabview';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { UserInfoStateService } from 'src/app/services/state-service/user-info-state.service';
@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoPipe,
    TabViewModule
  ],
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  /**
   * Template variables
   */
  CompanyTypeTableViewAsEnum = CompanyTypeTableViewAsEnum;
  ScreenEnum = ScreenEnum;
  activeTableView = CompanyTypeTableViewAsEnum.Buyer;

  constructor(public traceabilityStateService: TraceabilityStateService, public userInfoStateService: UserInfoStateService) {}

  onTabChange(index: number) {
    this.traceabilityStateService.resetTraceabilityState();
    switch(index) {
      case 0: 
        this.userInfoStateService.setViewAs(CompanyTypeTableViewAsEnum.Buyer);
      break;
      case 1: 
        this.userInfoStateService.setViewAs(CompanyTypeTableViewAsEnum.Seller);
      break;
    }
  }

  onBackClicked() {
    this.traceabilityStateService.setCurrentScreen(ScreenEnum.DASHBOARD);
  }
}

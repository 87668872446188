import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { EmailToCounterpartyPayload } from 'src/app/models/email-to-counterparty.mode';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { Subject, takeUntil } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-email-to-counterparty',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe
  ],
  templateUrl: './email-to-counterparty.component.html',
  styleUrls: ['./email-to-counterparty.component.scss']
})
export class EmailToCounterpartyComponent {
  counterparty: string = '';
  siNumber: string = '';
  counterpartyEmails = [];
  destroyed$ = new Subject<void>();
  isLoading = true;
  
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.siNumber = this.dialogConfig.data?.rowData?.si_number;
    this.counterparty = this.dialogConfig.data?.rowData?.counterparty === 'HG' ? 'HEVEA GLOBAL' : this.dialogConfig.data?.rowData?.counterparty;
    this.getCounterpartyEmails();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get formattedCounterpartyEmails(): string {
    return this.counterpartyEmails.join('; ');
  }

  getCounterpartyEmails() {
    this.dashboardService.getCounterpartyEmails(this.counterparty).pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this.counterpartyEmails = res.si_email_recipients;
        } 
        this.isLoading = false;
      },
      error: () => {
        this.closeModal()
      }
    });
  } 

  sendEmailToCounterparty() {
    const siList = [this.dialogConfig.data?.rowData?.si_number];
    const siDetailList = [this.dialogConfig.data?.rowData?.sidetail_id];

    this.dashboardService.emailToCounterParty(siList, siDetailList, this.counterpartyEmails);
    this.dialogRef.close();
  }
  
  closeModal() {
    this.dialogRef.close();
  }
}

import { FormControl, FormGroup } from "@angular/forms";

export interface AddDynamicControlFormControl {
    title: FormControl<string | null>;
    value: FormControl<string | null>;
}

export interface AddDynamicControlFormControlGroup {
    groupName: string;
    group: FormGroup<AddDynamicControlFormControl>;
}
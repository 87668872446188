// Module
var code = `<html>
    <head>
        <meta charset="utf-8">
        {{ no_to_sans_link }}
        <style>
            html {
                background-color: #efeeee;
                margin: 0px;
                display: table;
            }
        
            body {
                width: 100%;
                margin: 0;
            }
        
            .wrapper {
                background-color: white;
                width: 640px;
            }
        
            .title {
                padding-bottom: 8px;
            }
        
            .title label {
                font-weight: 600;
            }
        
            .logo-container {
                padding-bottom: 20px;
            }
        
            .logo-container img {
                width: 140px;
            }
        
            .sub-title {
                padding: 0px 0px 10px 0px;
            }
        
            .sub-title span {
                font-size: 12px;
            }
        
            .section-wrapper {
                padding: 0px 0px 10px 0px
            }
        
            .first-section-wrapper {
                padding-top: 0px;
            }
        
            .section-title {
                background-color: #eff0fe;
                padding: 5px;
                font-size: 14px;
                border-radius: 5px;
                margin-bottom: 5px;
            }
        
            .section-total {
                display: flex;
            }
        
            .section-total-item {
                display: flex;
                flex-direction: column;
                margin: 0px 60px 5px 0px;
            }
        
            .section-total-item-title {
                font-size: 14px;
                font-weight: 400;
            }
        
            .section-total-item-value {
                font-size: 20px;
                font-weight: 600;
            }
        
            .section-row-items {
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        
            .section-row-item-container {
                display: flex;
                align-items: center;
            }

            .has-open-input .section-row-item-value-container, 
            .has-open-input .section-row-item-container {
                width: 50%;
            }

            .has-open-input .section-row-item-value-container {
               text-align: right;
            }

            .has-open-input .section-row-item-value {
                word-wrap: break-word;
            }
        
            .risk-item {
                width: 80%;
            }
        
            .section-row-item-value {
                font-weight: 600;
            }
        
            .section-icon-container {
                padding-right: 10px;
            }
        
            .border-line {
                width: 100%;
                background-color: #c1c1c1;
                border-top: solid 1px #c1c1c1;
                border-bottom-style: none;
                margin: 5px 0px;
            }
        
            .disclaimer-title {
                font-size: 20px;
                color: #273065;
            }
        
            .disclaimer-content {
                font-size: 12px;
                text-align: justify;
            }
        
            .sign-section-container {
                display: flex;
                justify-content: space-between;
                padding-top: 30px;
                align-items: flex-end;
            }

            .sign-section-container > div {
                width: 50%
            }

            .sign-section-container .date-wrapper {
                display: inline-flex;
                flex-direction: column;
                align-items: end;
            }
        
            .sign-data-field {
                width: 200px;
                border-top: solid 1px;
                font-size: 10px;
                padding-top: 3px;
            }
        
            .footer-container {
                padding: 30px 30px 30px 0px;
            }
        
            .footer-container img {
                width: 140px;
            }
        
            .check-icon {
                box-sizing: border-box;
                position: relative;
                display: block;
                transform: scale(var(--ggs, 0.8));
                width: 15px;
                height: 22px;
            }
        
            .check-icon::after {
                content: "";
                display: block;
                box-sizing: border-box;
                position: absolute;
                left: 3px;
                top: 0px;
                width: 6px;
                height: 12px;
                border-width: 0 2px 2px 0;
                border-style: solid;
                border-top-style: none;
                transform-origin: bottom left;
                transform: rotate(45deg);
                color: #4caf50;
            }
        
            .close-icon {
                text-decoration: none;
                font-size: 14px;
                color: #ff5858;
            }
        
            .warn,
            .warn::before,
            .warn::after {
                position: relative;
                padding: 0;
                margin: 0;
            }
        
            .warn {
                font-size: 11px;
                color: transparent;
            }
        
            .warn.warning {
                display: inline-block;
                top: 0.09em;
                width: 1.15em;
                height: 1.15em;
                overflow: hidden;
                border: none;
                background-color: transparent;
                border-radius: 0.625em;
            }
        
            .warn.warning::before {
                content: "";
                display: block;
                top: -0.08em;
                left: 0.0em;
                position: absolute;
                border: transparent 0.6em solid;
                border-bottom-color: #f2c24f;
                border-bottom-width: 1em;
                border-top-width: 0;
            }
        
            .warn.warning.green::before {
                border-bottom-color: #4f9844;
            }
        
            .warn.warning::after {
                display: block;
                position: absolute;
                top: 0.3em;
                left: 0;
                width: 100%;
                padding: 0 0.5px;
                text-align: center;
                font-family: "Garamond";
                content: "!";
                font-size: 0.65em;
                font-weight: bold;
                color: #fff;
            }
        
            .polygons-container {
                margin-top: 6px;
            }
        
            .gps-point-container {
                margin-top: 5px;
            }
        
            .polygons-value {
                margin-left: 14px;
                font-weight: 700;
            }
        
            .polygons-icon {
                width: 12px;
                margin-right: 5px;
            }
        
            .gps-point-value {
                margin-left: 10px;
                font-weight: 700;
            }
        
            .gps-point-icon {
                width: 11px;
                margin-right: 5px;
            }
        
            .processor-name {
                font-size: 12px;
            }
        
            .process-date {
                width: 200px;
                font-size: 12px;
            }
        
            .si-table {
                width: 100%;
                border-collapse: collapse;
                border-bottom: solid 1px #c1c1c1;
            }
        
            .si-table th {
                font-size: 11px;
                background-color: #f9f9fd;
                padding: 10px 0px;
                text-align: center;
                border-top: solid 1px #c1c1c1;
                border-bottom: solid 1px #c1c1c1;
                font-weight: 400;
            }
        
            .si-table td {
                font-size: 10px;
                text-align: center;
                padding: 5px 0px;
            }
        
            .risk-layer-table {
                width: 100%;
                border-collapse: collapse;
                border-bottom: solid 1px #c1c1c1;
            }
        
            .risk-layer-table th {
                font-size: 11px;
                background-color: #f1f1f1;
                padding: 10px 0px;
                text-align: center;
                border-top: solid 1px #c1c1c1;
                border-bottom: solid 1px #c1c1c1;
                font-weight: 400;
            }
        
            .risk-layer-table td {
                font-size: 10px;
                text-align: center;
                padding: 5px 0px;
            }
        
            .si-numb-row,
            .risk-layer-row {
                margin: 6px 0px;
            }
        
            .section-row-margin-top {
                margin-top: 20px;
            }
        
            .summary-container {
                margin-top: 15px;
            }
        </style>
    </head>
    <body {{ font_style }}>
        {{ body }}
    </body>
</html>
`;
// Exports
export default code;
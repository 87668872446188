import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import {
  INITIAL_PAGED_DATA_STATE,
  SiSummary,
  TableFilters,
  TraceabilityData
} from 'src/app/models/traceability-state.model';
import { FormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  debounce,
  filter,
  merge,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
  map,
} from 'rxjs';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { TableTypeEnum } from 'src/app/enums/table-types.enum';
import { TraceabilityDataKeyEnum } from 'src/app/enums/table-data-keys.enum';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateNewSiModalComponent } from '../create-new-si-modal/create-new-si-modal.component';
import { LinkPlantationModalComponent } from '../link-plantation-modal/link-plantation-modal.component';
import { Renderer2 } from '@angular/core';
import { ScreenEnum, TraderLinkScreenEnum } from 'src/app/enums/screens.enum';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { GenerateDdsModalComponent } from '../generate-dds-modal/generate-dds-modal.component';
import { DownloadOptionsEnum } from 'src/app/enums/download-options.enum';
import { DownloadService } from 'src/app/services/download.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import {
  INITIAL_TRACEABILITY_TABLE_PARAMS,
  POLYGON_DATA_FILTER_OPTIONS,
  PUBLISH_STATUS_FILTER_OPTIONS,
} from 'src/app/constants/traceability-table.const';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import {
  COUNTRY_TABLE_COLUMNS,
  EU_IS_TABLE_COLUMNS,
  SI_NUMBER_TABLE_COLUMNS,
  TRADER_LINK_SUMMARY_SI_NUMBER_TABLE_COLUMNS,
} from 'src/app/constants/table-columns.const';
import { CreateNewSiOptionsEnum } from 'src/app/enums/create-new-options.enum';
import { UploadEudrPackageModalComponent } from '../upload-eudr-package-modal/upload-eudr-package-modal.component';
import { EmailToCounterpartyComponent } from '../email-to-counterparty/email-to-counterparty.component';
import { DDSModalModesEnum, TraceabilityTableTabsEnum } from 'src/app/enums/traceability-table-tabs.enum';
import { DdsSubmissionConfirmationModalComponent } from '../dds-submission-form-modal/dds-submission-confirmation-modal/dds-submission-confirmation-modal.component';
import { DdsSubmissionFormModalComponent } from '../dds-submission-form-modal/dds-submission-form-modal.component';
import { EuIsCredentialsComponent } from '../dds-submission-form-modal/eu-is-credentials/eu-is-credentials.component';
import { EuisService } from 'src/app/services/data-service/euis.service';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { saveAs } from 'file-saver';
import { UserInfoStateService } from 'src/app/services/state-service/user-info-state.service';
import { UserInfo, UserViewAsSetting } from 'src/app/models/user-info-state.model';
import { SettingsStateService } from 'src/app/services/state-service/settings-state.service';
import { Settings } from 'src/app/models/settings-state.model';
import { CompanyTypesEnum } from 'src/app/enums/company-types.enum';
import { GenerateDopModalComponent } from '../generate-dop-modal/generate-dop-modal.component';
import { CopySiRequest, CopySiResponse } from 'src/app/models/copy-si.model';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { TraderStateService } from 'src/app/services/state-service/trader-state.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-traceability-table',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    RiskIconComponent,
    TranslocoPipe,
    CreateNewSiModalComponent,
  ],
  templateUrl: './traceability-table.component.html',
  styleUrls: ['./traceability-table.component.scss'],
})
export class TraceabilityTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt!: Table;
  @Input() period = '';
  columns: ColumnDefinition[] = [];
  INITIAL_PAGED_DATA_STATE = INITIAL_PAGED_DATA_STATE;
  rowData: TraceabilityData[] = [];
  tableDataKey: TraceabilityDataKeyEnum = TraceabilityDataKeyEnum.SI_NUMBER;
  ref!: DynamicDialogRef;
  isUnlocked: boolean = false;
  userType: string = '';

  plantationProcessing = 'Plantation details are still being processed.'

  DDSModalModesEnum = DDSModalModesEnum;
  CompanyTypesEnum = CompanyTypesEnum;
  TraderLinkScreenEnum = TraderLinkScreenEnum;
  selectedItems: TraceabilityData[] = [];
  selectedItemNames: string[] = [];
  globalFilterFields: string[] = [];
  filters: TableFilters = {};
  selectedFilterValues: { [key: string]: any } = {};
  filterOptions: { key: string; options: any }[] = [];
  filterChanged$ = new BehaviorSubject<{
    isChanged: boolean;
    isDropdown: boolean;
    field: string;
    values: any;
    function?: () => {};
  } | null>(null);
  sortProps: { sortField: string; sortOrder: number } = {
    sortField: '',
    sortOrder: 1,
  };
  first = 10;
  sortPropsClone = {};
  private filterParams = '';

  pageRows: number = 10;
  totalRecords!: number;
  downloadOptions = DownloadOptionsEnum;
  secretKey!: string | null;
  euisAdapterLoaded = true;

  isInitialLoad = true;
  tableViewOptions = [
    {
      label: 'SI Number',
      value: TableTypeEnum.SI_NUMBER,
    },
    {
      label: 'Country',
      value: TableTypeEnum.COUNTRY,
    },
  ];
  pdfLoading = false;

  selectedTableView = TableTypeEnum.SI_NUMBER;
  selectedTab = TraceabilityTableTabsEnum.TRACEABILITY;

  destroyed$ = new Subject<void>();

  tableParams: any = INITIAL_TRACEABILITY_TABLE_PARAMS;
  modeParam: { [param: string]: string | number | boolean } | null = null;
  isLoadPersistedFilters = false;
  isSettingsMapped = false;

  createNewSiOptionProps: any[] = [];
  downloadOptionProps = [
    {
      label: this.translateOptionLabel('POLYGON_DATA_FOR_EUIS'),
      loadingState: this.eventStateService.downloadEuisLoading$,
      downloadOption: DownloadOptionsEnum.POLYGON_DATA_EUIS,
      hasDisabledCondition: false,
    },
    {
      label: this.translateOptionLabel('COMBINED_POLYGON_DATA_FOR_EUIS'),
      loadingState: this.eventStateService.downloadCombinedEuisLoading$,
      downloadOption: DownloadOptionsEnum.COMBINED_POLYGON_DATA_EUIS,
      hasDisabledCondition: false,
    },
    {
      label: this.translateOptionLabel('SI_INFORMATION'),
      downloadOption: DownloadOptionsEnum.SI_INFORMATION,
      hasDisabledCondition: false,
    },
    {
      label: this.translateOptionLabel('FULL_RISK_REPORT'),
      loadingState: this.eventStateService.downloadFullRiskLoading$,
      downloadOption: DownloadOptionsEnum.FULL_RUSK_REPORT,
      hasDisabledCondition: false,
    },
    {
      label: this.translateOptionLabel('EUDR_PACKAGE'),
      loadingState: this.eventStateService.downloadEudrPackageLoading$,
      downloadOption: DownloadOptionsEnum.EUDR_PACKAGE,
      hasDisabledCondition: true,
    },
  ];

  createNewSiOptions = CreateNewSiOptionsEnum;
  siDetailIds: any[] = [];

  polygonDataFilterOptions = POLYGON_DATA_FILTER_OPTIONS.options.map(
    (option) => ({
      ...option,
      label: this.translocoService.translate(option.label),
    })
  );

  publishStatusFilterOptions = PUBLISH_STATUS_FILTER_OPTIONS.options.map(
    (option) => ({
      ...option,
      label: this.translocoService.translate(option.label),
    })
  );

  isTraderLinkingTRN = false;
  traderNewSi?: TraceabilityData | null;

  get tableTitle() {
    let title = this.translocoService.translate(
      'DASHBOARD.MY_TRACEABILITY_TABLE'
    );

    if (this.isTraderLinkingTRN) {
      title =
        this.traderStateService.selectedScreen() ===
        TraderLinkScreenEnum.SELECT_TRADE
          ? this.translocoService.translate(
              'DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.TABLE_TITLE_LIST'
            )
          : this.translocoService.translate(
              'DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.TABLE_TITLE_SUMMARY'
            );
    }

    return title;
  }

  get isTrader() {
    return this.utilityService.retrieveFromObservable(
      this.userStateService.isTrader$
    ) as boolean;
  }

  get disableTraderNextAction() {
    const noSelectedSiOrCreateSiOption = !(this.isTraderLinkingTRN &&
    ((this.selectedItems?.length > 0 && this.rowData.length > 0) ||
      this.traderStateService.showCreateSiModal()) &&
    this.traderStateService.selectedScreen() ===
      TraderLinkScreenEnum.SELECT_TRADE
      ? true
      : this.traderStateService.selectedScreen() !==
        TraderLinkScreenEnum.SELECT_TRADE);

    return (
      noSelectedSiOrCreateSiOption || this.traderStateService.disableCreateSi()
    );
  }

  get showTableHeader() {
    return this.isTraderLinkingTRN &&
      this.traderStateService.selectedScreen() ===
        TraderLinkScreenEnum.SELECT_TRADE
      ? true
      : !this.isTraderLinkingTRN;
  }

  get enableTraderShareOrCombine() {
    return (
      this.selectedItems &&
      this.selectedItems.length > 0 &&
      this.selectedItems.every((item) => item.has_plantation_data)
    );
  }

  get isListControlsDisabled() {
    return this.selectedItems.length <= 0;
  }

  get dopEnabled() {
    return (
      this.selectedItems.length === 1 &&
      !!this.selectedItems[0]?.has_plantation_data
    );
  }

  get isEudrPackageDisabled() {
    return (
      this.selectedItems.length > 1
      // TODO: comment for now
      //  || !this.selectedItems[0]?.has_eudr_package
    );
  }

  notUserCreated(rowData: TraceabilityData): boolean {
    let isDisabled = false;
    this.userStateService.userInfo$
      .pipe(take(1))
      .subscribe((user) => {
        isDisabled = user?.id !== rowData.created_by_user_id;
      });
    return isDisabled;
  }

  get publishTrnDisabled() {
    const companyIds = this.selectedItems.map(
      (item: any) => item.created_by_company_id
    );

    return combineLatest([
      this.settingsStateService.settings$,
      this.userStateService.userInfo$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([settings, user]: [Settings | null, UserInfo | null]) => {
        if (!settings || !user || this.selectedItems.length === 0) return true;

        const allCompanyIdsMatch = companyIds.every((id) => id === Number(user.company.id));
        const allCreatedByUser = this.selectedItems.every((item) => item.created_by_user_id === user.id);

        return !(allCompanyIdsMatch && allCreatedByUser);
      })
    );
  }

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    public traderStateService: TraderStateService,
    public settingsStateService: SettingsStateService,
    public userStateService: UserInfoStateService,
    private dashboardService: DashboardService,
    private dialogService: DialogService,
    public eventStateService: EventStateService,
    private renderer: Renderer2,
    private downloadService: DownloadService,
    public utilityService: UtilityService,
    private translocoService: TranslocoService,
    private euisService: EuisService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.initializeTrader();
    this.initializeTraceabilityTable();
    this.initializeSelectedFilterValues();
    this.initializeTablePersistence();
    this.listenToTableRefresh();
    this.listenToFilterChange();
    this.initializeCreateNewSiOptions();
    this.checkSecretKey();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.traceabilityStateService.clearPagedTraceabilityData();
  }

  checkSecretKey() {
    const secretKey = sessionStorage.getItem('eu_is_secret_key');
    this.isUnlocked = !!secretKey;
  }

  onTabChange(index: number) {
    if (index !== this.selectedTab) {
      this.INITIAL_PAGED_DATA_STATE = INITIAL_PAGED_DATA_STATE;
    } else {
      return;
    }

    this.selectedTab = index;
    this.totalRecords = 0;
    this.pageRows = 10;
    this.selectedItems = [];
    this.rowData = [];

    this.sortProps = {
      sortField: '',
      sortOrder: 1,
    };
    this.sortPropsClone = {};
    this.tableParams = { ...this.tableParams, page: 10 }; // default

    if (this.selectedTab === TraceabilityTableTabsEnum.TRACEABILITY) {
      this.columns = SI_NUMBER_TABLE_COLUMNS;
    } else if (this.selectedTab === TraceabilityTableTabsEnum.EU_IS) {
      this.columns = EU_IS_TABLE_COLUMNS;
      this.secretKey = sessionStorage.getItem('eu_is_secret_key');
      if (!this.secretKey) {
        this.ref = this.dialogService.open(EuIsCredentialsComponent, {
          header: this.translateOptionLabel(
            'EU_IS_TABLE_COLUMNS.EU_IS_CREDENTIALS'
          ),
          width: '50%',
        });

        this.ref.onClose.subscribe((credentials: any) => {
          if (credentials) {
            sessionStorage.setItem('eu_is_secret_key', credentials.passkey);
            sessionStorage.setItem('eu_is_user', credentials.username);
            this.selectedTab = TraceabilityTableTabsEnum.EU_IS;
            this.isUnlocked = true;
            this.columns = EU_IS_TABLE_COLUMNS;
            this.retrieveEuIsList();
          } else {
            this.selectedTab = TraceabilityTableTabsEnum.TRACEABILITY;
            this.columns = SI_NUMBER_TABLE_COLUMNS;
          }
        });
      }
    }

    this.dt.first = 0;
    this.dt.ngOnInit();
  }

  initializeCreateNewSiOptions() {
    this.userStateService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userInfo) => {
        this.userType = userInfo?.company.type || '';
        this.createNewSiOptionProps = [
          {
            label: this.translateOptionLabel(
              'CREATE_NEW_SI.SELECT_COUNTERPARTY'
            ),
            option: CreateNewSiOptionsEnum.COUNTERPARTY,
          },
        ];
        if (this.userType !== CompanyTypesEnum.Trader) {
          this.createNewSiOptionProps.unshift({
            label: this.translateOptionLabel(
              'CREATE_NEW_SI.SELECT_TRADE_CONFIRMATION'
            ),
            option: CreateNewSiOptionsEnum.TRADE_CONFIRMATION,
          });
        }
      });
  }

  initializeTablePersistence() {
    combineLatest([
      this.traceabilityStateService.selectedTraceabilityData$,
      this.eventStateService.traceabilityTableParams$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([selectedData, persistedParams]) => {
        if (selectedData) {
          this.selectedItems = selectedData;
          this.selectedItemNames = selectedData.map(
            (selectedData) =>
              selectedData[this.tableDataKey as keyof TraceabilityData]!
          );
        }

        if (persistedParams?.tableParams) {
          this.isLoadPersistedFilters = true;
          persistedParams.tableParams.page = 0;
          this.tableParams = persistedParams.tableParams;
          this.filterParams = this.tableParams.filter;
          this.selectedFilterValues = persistedParams.filtersState;
          this.sortProps = persistedParams.sortProps;
        }
      });
  }

  private showTraderSummaryScreen() {
    const columns = structuredClone(this.columns);
    this.columns = columns.map((col) => {
      if (col.sortable || col.filter) {
        col.filter = false;
        col.sortable = false;
        col.filterName = undefined;
      }

      return col;
    });

    this.traderStateService.setSelectedScreen(TraderLinkScreenEnum.SUMMARY);

    if (this.traderNewSi) {
      this.selectedItems = [];
      const selected = this.rowData.find(
        (data) => data.sidetail_id === this.traderNewSi?.sidetail_id
      );
      if (selected) {
        this.selectedItems.push(selected);
      }
      this.traderStateService.setSelectedNewSi();
    }

    this.rowData = structuredClone(this.selectedItems ?? []);
    this.totalRecords = this.rowData.length;
  }

  private initializeTableParams() {
    const viewAsSetting = this.utilityService.retrieveFromObservable(
      this.userStateService.viewAsSetting$
    ) as UserViewAsSetting;
    this.modeParam = viewAsSetting ? viewAsSetting.param : null;
    this.tableParams = this.modeParam
      ? { ...this.tableParams, ...this.modeParam }
      : this.tableParams;

    const addHasPlantationFilter =
      this.tableParams?.filter?.indexOf('has_plantation_data=false') !== 1;
    if (this.isTraderLinkingTRN && addHasPlantationFilter) {
      this.tableParams.filter =
        this.tableParams.filter &&
        this.tableParams.filter.indexOf('has_plantation_data=false') !== 1
          ? `${this.tableParams.filter}&has_plantation_data=false`
          : `&has_plantation_data=false`;
    }
  }

  private initializeSiNumberTableColumns() {
    this.columns = this.isTraderLinkingTRN
      ? TRADER_LINK_SUMMARY_SI_NUMBER_TABLE_COLUMNS
      : SI_NUMBER_TABLE_COLUMNS;
  }

  private initializeTrader() {
    if (this.isTrader) {
      this.eventStateService.isTraderLinkingTRN$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (isTraderLinkingTRN: boolean) =>
            (this.isTraderLinkingTRN = isTraderLinkingTRN)
        );

      this.traderStateService.selectedNewSi$
        .pipe(
          filter(
            () =>
              this.isTraderLinkingTRN &&
              this.traderStateService.selectedScreen() ===
                TraderLinkScreenEnum.CONFIRMATION_DETAILS
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe((newSi) => {
          this.traderNewSi = newSi;
          if (this.traderNewSi) {
            this.traderStateService.setSelectedScreen(
              TraderLinkScreenEnum.SUMMARY
            );
          } else {
            this.traderStateService.setSelectedScreen(
              TraderLinkScreenEnum.SELECT_TRADE
            );
          }
          this.resetTraderCreateSi();
        });
    }
  }

  private resetTraderCreateSi() {
    this.traderStateService.setSelectedNewSi();
    this.traderStateService.setCreateSiDisabled();
    this.traderStateService.setShowCreateSiModal();
    this.traderStateService.setCreateNewSiAction();
    this.traderStateService.setSelectedCreateNewSiOption();
  }

  private resetTraderSettings() {
    this.traderStateService.reset();
    this.eventStateService.isTraderCopyingInProgress = false;
    this.eventStateService.isTraderLinkingTRN = false;
  }

  initializeTraceabilityTable() {
    this.initializeSiNumberTableColumns();
    this.initializeTableParams();
    this.initializeTraceabilityTableFilters();

    this.settingsStateService.settings$
      .pipe(
        switchMap((settings) =>
          this.traceabilityStateService.pagedTraceabilityData$.pipe(
            tap((data) => {
              if (data?.results?.length && !this.isSettingsMapped) {
                this.mapSettingsToRow(settings ?? null);
              }
            })
          )
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((data) => {
        this.globalFilterFields = this.columns.map((c) => c.field);

        this.mapSummaryToSi(data!.results!);
        this.rowData = data!.results! ?? [];
        this.totalRecords = data!.count;
        this.eventStateService.isTraceabilityTableLoading =
          data === INITIAL_PAGED_DATA_STATE;
        if (this.selectedItems.length) {
          this.selectedItems.forEach((item) => {
            this.rowData.forEach((rowData) => {
              if (rowData.si_number === item.si_number) {
                rowData.checked = true;
              }
            });
          });
        }

        if (
          this.isTraderLinkingTRN &&
          this.traderStateService.selectedScreen() ===
            TraderLinkScreenEnum.SUMMARY
        ) {
          this.showTraderSummaryScreen();
        }

        if (
          this.selectedTab === TraceabilityTableTabsEnum.EU_IS &&
          this.isUnlocked
        ) {
          this.retrieveEuIsList();
        }
      });
  }

  retrieveEuIsList() {
    this.euisAdapterLoaded = false;
    this.siDetailIds = this.rowData.map((data) => data.sidetail_id);
    this.euisService.getEuIsAdapterList(this.siDetailIds).subscribe(
      (res: any[]) => {
        if (res && res.length > 0) {
          // Create a lookup map for efficient matching
          const euiMap: { [key: string]: any[] } = res.reduce((map, eui) => {
            const key = eui.foreign_key;
            if (!map[key]) {
              map[key] = [];
            }
            map[key].push(eui);
            return map;
          }, {});

          // Map over rowData and append matching EuIs
          this.rowData = this.rowData.map((row) => ({
            ...row,
            ...(row.sidetail_id
              ? euiMap[row.sidetail_id.toString()]?.[0] ?? {}
              : {}),
          }));
        }
        this.euisAdapterLoaded = true;
      },
      (error) => {
        console.error('Failed to retrieve EuIs:', error);
      }
    );
  }

  mapSettingsToRow(settings: Settings | null) {
    const siPageMapping = settings?.mapping?.si_page as any;

    if (siPageMapping) {
      this.columns.forEach((column) => {
        if (siPageMapping[column.field]) {
          column.field = siPageMapping[column.field];
        }
      });
    }

    this.isSettingsMapped = true;
  }

  mapSummaryToSi(data: TraceabilityData[]) {
    if (data?.length) {
      const siList = this.utilityService.getSiListArray(data);
      const siDetailList = this.utilityService.getSiDetailListArray(data);
      this.eventStateService.isSiSummariesLoaded = false;
      this.traceabilityStateService.getSiSummaries(siList, siDetailList);
      this.traceabilityStateService.siSummaries$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((summaries) => {
          if (summaries?.length) {
            this.rowData = [
              ...this.rowData.map((data) => {
                const siWithRisks = summaries.filter(
                  (summary) =>
                    data.si_number === summary.si_numb &&
                    summary.risks &&
                    Object.entries(summary.risks)?.length
                );
                const mappedSummary = summaries.find(
                  (summary) => summary.si_numb === data.si_number
                );
                return {
                  ...data,
                  risks: this.extractRisks(siWithRisks),
                  summary: mappedSummary,
                };
              }),
            ];
          }
        });
    }
  }

  extractRisks(summaries: SiSummary[]): string[] {
    const rowDataRisks: string[] = [];

    summaries.forEach((summary) => {
      this.checkAndPushRisk(rowDataRisks, summary, RiskTypesEnum.WDPA);
      this.checkAndPushRisk(
        rowDataRisks,
        summary,
        RiskTypesEnum.TREE_COVER_LOSS
      );
    });

    return rowDataRisks;
  }

  checkAndPushRisk(
    rowDataRisks: string[],
    summary: SiSummary,
    riskType: RiskTypesEnum
  ) {
    if (!rowDataRisks.includes(riskType) && summary.risks[riskType]) {
      rowDataRisks.push(riskType);
    }
  }

  initializeTraceabilityTableFilters() {
    this.traceabilityStateService.getSiPageFilters(this.modeParam);
    this.traceabilityStateService.siPageTableFilters$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.filters = res!;
        for (const key in this.filters) {
          // TODO: move logic to data layer service
          let delivery_month = key === 'delivery_month';
          let options = this.filters[key]?.options.map((option) => ({
            label: delivery_month ? this.formatDate(option) : option,
            value: delivery_month ? this.formatDate(option) : option,
          }));
          this.filterOptions.push({ key: key, options: options });
        }
      });
  }

  initializeSelectedFilterValues() {
    // Assuming 'columns' is already defined or initialized before this method is called
    this.columns.forEach((column) => {
      this.selectedFilterValues[column.field] = [];
    });
  }

  formatDate(dateString: string, time = false) {
    const date = new Date(dateString);
    if (time) {
      const date = new Date(dateString);

      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    }
    return date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });
  }

  listenToTableRefresh() {
    merge(
      this.eventStateService.isNewSiCreated$,
      this.eventStateService.isPlantationLinked$
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.lazyLoadTraceabilityTable();
      });
  }

  listenToFilterChange() {
    this.filterChanged$
      .pipe(debounce((filter) => (!filter?.isDropdown ? timer(1000) : of(0))))
      .subscribe((filter) => {
        if (filter?.isChanged) {
          this.processFilterChange(
            filter?.field,
            this.selectedFilterValues[filter?.field]
          );
          filter?.function?.apply(filter.values!);
        }
      });
  }

  getFilterOptions(field: string) {
    if (field === 'has_plantation_data') {
      return this.polygonDataFilterOptions;
    }

    if (field === 'publish_status') {
      return this.publishStatusFilterOptions;
    }

    let foundObject = this.filterOptions.find((obj) => obj.key === field);
    if (field === 'counterparty' && !foundObject) {
      foundObject = this.filterOptions.find((obj) => obj.key === 'producer');
    }
    return foundObject ? foundObject.options : [];
  }

  onFilterChange(field: string, values: any, filter: any, isDropdown = false) {
    this.selectedFilterValues[field] = values;
    this.filterChanged$.next({
      isChanged: true,
      isDropdown: isDropdown,
      field: field,
      values: values,
      function: filter,
    });
  }

  onClearFilter(field: string, filter_name: string) {
    this.selectedFilterValues[field] = [];
    this.dt.clearFilterValues();
    this.onApplyFilter(field, filter_name);
  }

  // TODO: refactor
  onApplyFilter(field: string, filter_name: string) {
    this.processFilterChange(filter_name, this.selectedFilterValues[field]);
  }

  processFilterChange(field: string, values: string[]) {
    const paramRegex = new RegExp(`&${field}=[^&]*`, 'g');
    // Appends value if same filter field
    if (this.filterParams.includes(field)) {
      this.filterParams = this.filterParams.replace(
        paramRegex,
        this.getConstructedParams(field, values)
      );
    } else {
      this.filterParams += this.getConstructedParams(field, values);
    }

    if (field === 'has_plantation_data' && typeof values === 'boolean') return;

    // Removes filter params on clear
    if (!values.length) {
      this.filterParams = this.filterParams.replace(paramRegex, '');
      this.lazyLoadTraceabilityTable();
    }

    // this.hideFilterOverlay();
  }

  hideFilterOverlay() {
    const overlay = document.querySelector('.p-column-filter-overlay');
    if (overlay) {
      this.renderer.setStyle(overlay, 'display', 'none');
    }
  }

  getConstructedParams(field: string, values: string[]) {
    if (field === 'has_plantation_data') {
      return `&${field}=` + values;
    }

    if (field === 'publish_status') {
      return `&${field}=` + values;
    }

    return values.length > 0 ? `&${field}=` + values.join(',') : '';
  }

  getFilterClass(value: string | string[]) {
    return typeof value === 'boolean' || value?.length > 0
      ? 'active-filter'
      : 'inactive-filter';
  }

  get isTraceabilityTable() {
    return this.selectedTab === TraceabilityTableTabsEnum.TRACEABILITY;
  }
  get isEuIsTable() {
    return this.selectedTab === TraceabilityTableTabsEnum.EU_IS;
  }

  lazyLoadTraceabilityTable(event?: TableLazyLoadEvent) {
    this.eventStateService.isTraceabilityTableLoading = true;

    if (!this.isLoadPersistedFilters) {
      this.customSort(event);
      this.tableParams.pageSize = event?.rows ?? 10;
      this.tableParams.page = event?.first ?? 0;
      this.tableParams.searchTerm = event?.globalFilter;
      this.tableParams.filter = this.filterParams;
    }
    this.isLoadPersistedFilters = false;
    this.initializeTableParams();
    this.traceabilityStateService.getSiPage(
      this.userStateService.userViewAs$,
      this.tableParams
    );
  }

  onClearAllClicked() {
    this.selectedItems = [];
    this.selectedItemNames = [];
  }

  onSelectedTableViewChange() {
    this.eventStateService.isTraceabilityTableLoading = true;
    this.selectedItemNames = [];
    this.selectedItems = [];

    this.traceabilityStateService.clearPagedTraceabilityData();
    if (this.selectedTableView === TableTypeEnum.SI_NUMBER) {
      this.columns = SI_NUMBER_TABLE_COLUMNS;
      this.initializeTableParams();
      this.traceabilityStateService.getSiPage(
        this.userStateService.userViewAs$,
        this.tableParams
      );
      this.traceabilityStateService.getSiPageFilters(this.modeParam);
      this.tableDataKey = TraceabilityDataKeyEnum.SI_NUMBER;
      return;
    }

    this.columns = COUNTRY_TABLE_COLUMNS;
    this.traceabilityStateService.getCountryPage();
    this.tableDataKey = TraceabilityDataKeyEnum.COUNTRY;
  }

  customSort(event?: TableLazyLoadEvent) {
    let sortParam = 'si_buyer__buyer_name';
    if (event?.sortField) {
      switch (event?.sortField) {
        case 'counterparty':
          sortParam = 'si_buyer__buyer_name';
          break;
        case 'delivery_datetime':
          sortParam = 'si_ship_dt';
          break;
        case 'created_at':
          sortParam = 'created_at';
          break;
      }
      this.tableParams.ordering =
        event?.sortOrder === -1 ? '-' + sortParam : sortParam;
      this.eventStateService.traceabilityTableParams = {};
      this.sortPropsClone = {
        sortField: event.sortField.toString(),
        sortOrder: event.sortOrder!,
      };
    }
  }

  applyFilterGlobal(event: Event, filterType: string) {
    this.dt.filterGlobal((event.target as HTMLInputElement).value, filterType);
  }

  onShowMap() {
    this.traceabilityStateService.setSelectedTraceabilityData(
      this.selectedItems
    );
    this.traceabilityStateService.setCurrentScreen(ScreenEnum.MAP_PAGE);
    this.eventStateService.traceabilityTableParams = {
      tableParams: { ...this.tableParams },
      filtersState: { ...this.selectedFilterValues },
      sortProps: { ...this.sortPropsClone },
    };
  }

  private hasPendingPlantation(rowData: TraceabilityData) {
    return rowData?.summary?.error === this.plantationProcessing;
  }

  hasMissingOrPendingPlantation(rowData: TraceabilityData) {
    return (this.hasPendingPlantation(rowData) || !rowData.has_plantation_data) && !this.isTraderLinkingTRN;
  }

  plantationErrorMessage(rowData: TraceabilityData) {
    let message = '';
    if (!rowData.has_plantation_data) {
      message = this.translateOptionLabel('PLANTATION_MISSING');
    } else if (this.hasPendingPlantation(rowData)) {
      message = this.translateOptionLabel('PLANTATION_PROCESSING');
    }

    return message;
  }

  transformStatus(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  publishTRNs() {
    const siDetailIds = this.selectedItems
      .map((data) => data.sidetail_id)
      .filter((id): id is number => id !== undefined);
    this.confirmationService.confirm({
      key: 'publish-modal',
      message: this.translocoService.translate(
        'DASHBOARD.PUBLISH_DELETE_TRN_CONFIRM.PUBLISH_MSG'
      ),
      header: this.translocoService.translate(
        'DASHBOARD.PUBLISH_DELETE_TRN_CONFIRM.PUBLISH_CONFIRMATION'
      ),
      defaultFocus: 'reject',
      acceptLabel: this.translocoService.translate(
        'DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.ACCEPT_BUTTON_LABEL'
      ),
      rejectLabel: this.translocoService.translate(
        'DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.REJECT_BUTTON_LABEL'
      ),
      rejectIcon: '_',
      acceptIcon: '_',
      accept: () => {
        this.dashboardService.publishTRNs(siDetailIds).subscribe({
          next: () => {
            this.utilityService.emitToast({
              message: this.translocoService.translate(
                'DASHBOARD.PUBLISH_DELETE_TRN_CONFIRM.PUBLISH_SUCCESS'
              ),
              isSuccess: true,
            });
            this.lazyLoadTraceabilityTable();
          },
          error: (error) => {
            console.error('Error publishing TRN', error);
          },
        });
      },
      reject: () => {},
    } as Confirmation);
  }

  deleteTRN(rowData: any) {
    const col = this.columns.find(
      (col) => col.displayName === 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.SI_NUMBER'
    );
    this.confirmationService.confirm({
      key: 'delete-trn-modal',
      message: this.translocoService.translate(
        'DASHBOARD.PUBLISH_DELETE_TRN_CONFIRM.DELETE_MSG',
        {
          trn: rowData[col?.field] || '-',
          counterparty: rowData.counterparty,
        }
      ),
      header: this.translocoService.translate(
        'DASHBOARD.PUBLISH_DELETE_TRN_CONFIRM.DELETE_CONFIRMATION'
      ),
      defaultFocus: 'reject',
      acceptLabel: this.translocoService.translate(
        'DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.ACCEPT_BUTTON_LABEL'
      ),
      rejectLabel: this.translocoService.translate(
        'DASHBOARD.DDR.DYNAMIC_FIELD.DELETE_CONFIRMATION_DIALOG.REJECT_BUTTON_LABEL'
      ),
      rejectIcon: '_',
      acceptIcon: '_',
      accept: () => {
        this.dashboardService.deleteTRN(rowData.sidetail_id).subscribe({
          next: () => {
            this.utilityService.emitToast({
              message: this.translocoService.translate(
                'DASHBOARD.PUBLISH_DELETE_TRN_CONFIRM.DELETE_SUCCESS'
              ),
              isSuccess: true,
            });
            this.lazyLoadTraceabilityTable();
          },
          error: (error) => {
            console.error('Error deleting TRN', error);
          },
        });
      },
      reject: () => {},
    } as Confirmation);
  }

  onGenerateDdsClicked() {
    this.eventStateService.isDownloadDdrLoading = false;
    this.eventStateService.isDdrDownloaded = false;
    const ref: DynamicDialogRef = this.dialogService.open(
      GenerateDdsModalComponent,
      {
        width: '50%',
        styleClass: 'dds-modal',
        header: this.translocoService.translate(
          'DASHBOARD.DDR.DUE_DILIGENCE_REPORT'
        ),
        data: this.selectedItems,
      }
    );

    combineLatest([ref.onClose, this.eventStateService.isDdrDownloaded$])
      .pipe(
        filter(([_, isDdrDownloaded]) => isDdrDownloaded),
        take(1)
      )
      .subscribe(() => {
        this.isLoadPersistedFilters = true;
        this.eventStateService.isDownloadDdrLoading = false;
        this.eventStateService.isDdrDownloaded = false;
        this.lazyLoadTraceabilityTable();
      });
  }

  onGenerateDopClicked() {
    const ref: DynamicDialogRef = this.dialogService.open(
      GenerateDopModalComponent,
      {
        width: '80%',
        styleClass: 'dds-modal',
        header: this.translocoService.translate('DASHBOARD.DOP.GENERATE_DOP'),
        data: this.selectedItems,
      }
    );

    ref.onClose.subscribe((result) => {
      this.isLoadPersistedFilters = true;
      this.lazyLoadTraceabilityTable();
    });
  }

  onDownloadClicked(downloadOption: DownloadOptionsEnum) {
    switch (downloadOption) {
      case DownloadOptionsEnum.POLYGON_DATA_EUIS:
        this.eventStateService.downloadEuisLoading = true;
        this.downloadService.download(downloadOption, this.selectedItems);
        break;
      case DownloadOptionsEnum.COMBINED_POLYGON_DATA_EUIS:
        this.eventStateService.downloadCombinedEuisLoading = true;
        this.downloadService.download(downloadOption, this.selectedItems);
        break;
      case DownloadOptionsEnum.SI_INFORMATION:
        this.downloadService.downloadSiInformation(this.selectedItems);
        break;
      case DownloadOptionsEnum.FULL_RUSK_REPORT:
        this.eventStateService.downloadFullRiskLoading = true;
        this.downloadService.download(downloadOption, this.selectedItems);
        break;
      case DownloadOptionsEnum.EUDR_PACKAGE:
        this.eventStateService.downloadEudrPackageLoading = true;
        this.downloadService.download(downloadOption, this.selectedItems);
        break;
      default:
        break;
    }
  }

  onHeaderCheckboxToggle(event: { checked: boolean }): void {
    const isChecked = event.checked;

    if (isChecked) {
      const selectableRows = this.getSelectableRows();
      this.selectedItems = this.selectedItems.concat(
        selectableRows.filter(
          row => !this.selectedItems.some(selected => selected[this.tableDataKey] === row[this.tableDataKey])
        )
      );
    } else {
      const removableRows = this.getRemovableRows();
      this.selectedItems = this.selectedItems.filter(
        selected => !removableRows.some(row => row[this.tableDataKey] === selected[this.tableDataKey])
      );
    }

    this.selectedItemNames = this.selectedItems.map(
      (data) => data[this.tableDataKey as keyof TraceabilityData]!
    );

    this.updateRowCheckedStates();
  }

  onSelectedItemRemove(dataKey: string) {
    const deletedIndex = this.selectedItems.findIndex(
      (p) => p[this.tableDataKey as keyof TraceabilityData] === dataKey
    );

    const tempSelectedsi = [...this.selectedItems];
    tempSelectedsi.splice(deletedIndex, 1);

    this.selectedItems = [...tempSelectedsi];
  }

  onUploadClicked() {}

  onSelectionChange(data: TraceabilityData[]) {
    if (this.isTraderLinkingTRN) {
      this.resetTraderCreateSi();
    }

    this.selectedItemNames = data.map(
      (data) => data[this.tableDataKey as keyof TraceabilityData]!
    );
    const selectedItemKeys = [...this.selectedItemNames];
    this.rowData.forEach((row) => {
      if (
        selectedItemKeys.includes(
          row[this.tableDataKey as keyof TraceabilityData]!
        )
      ) {
        row.checked = true;
        return;
      }

      row.checked = false;
    });
  }

  onCreateNewSiClicked(
    option: CreateNewSiOptionsEnum,
    overlayPanel?: OverlayPanel
  ) {
    if (this.isTraderLinkingTRN) {
      if (overlayPanel) {
        overlayPanel.hide();
      }
      this.traderStateService.setShowCreateSiModal(true);
      this.traderStateService.setSelectedCreateNewSiOption(option);
      this.onConfirmTraderLink();
    } else {
      this.dialogService.open(CreateNewSiModalComponent, {
        width: '50%',
        header: this.translocoService.translate(
          'DASHBOARD.CREATE_NEW_SI.CREATE_NEW_SI'
        ),
        data: option,
      });
    }
  }

  onLinkPlantationClicked(rowData: TraceabilityData) {
    const param = { rowData: rowData, period: this.period };
    const data = this.modeParam ? { ...param, ...this.modeParam } : param;
    this.dialogService.open(LinkPlantationModalComponent, {
      width: '50%',
      header: `${this.translocoService.translate(
        'DASHBOARD.SI_LINKING.LINK_PLANTATION_MODAL_HEADER'
      )} ${rowData.si_number}`,
      data: data,
    });
  }

  onUploadEudrPackage(rowData: TraceabilityData, has_eudr_package: boolean, notUserCreated: boolean) {
    const ref: DynamicDialogRef = this.dialogService.open(
      UploadEudrPackageModalComponent,
      {
        width: '60%',
        header: `${this.translocoService.translate(
          'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.UPLOAD_DOCUMENTS'
        )}`,
        data: { rowData: rowData, has_eudr_package, userType: this.userType, userCreatedTrn: !notUserCreated},
      }
    );

    ref.onClose.subscribe((result) => {
      this.isLoadPersistedFilters = true;
      this.lazyLoadTraceabilityTable();
    });
  }
  downloadDdrPdf(rowData: TraceabilityData) {
    this.pdfLoading = true;
    this.dashboardService.getSiDdrData(rowData.sidetail_id || 0).subscribe({
      next: (ddrData: any) => {
        if (ddrData.length > 0) {
          this.dashboardService
            .getDdrDownloadPdf(ddrData[0].ddreport_reference_num)
            .subscribe({
              next: (res: any) => {
                if (res.url) {
                  saveAs(res.url, `${rowData.si_number}_DDR.pdf`);
                }
                this.pdfLoading = false;
              },
              error: (err) => {
                console.error('Error fetching DDR PDF:', err);
                this.pdfLoading = false;
              },
              complete: () => {
                this.pdfLoading = false;
              },
            });
        } else {
          this.pdfLoading = false;
        }
      },
      error: (err) => {
        console.error('Error fetching DDR data:', err);
        this.pdfLoading = false;
      },
      complete: () => {
        this.pdfLoading = false;
      },
    });
  }

  translateOptionLabel(label: string) {
    return this.translocoService.translate(`DASHBOARD.${label}`);
  }

  onRowEditInit(rowData: TraceabilityData, index: number) {}
  onEdit(rowData: TraceabilityData, index: number) {}
  onDelete(rowData: TraceabilityData, index: number) {}
  onRowEditCancel(rowData: TraceabilityData, index: number) {}
  onRowEditSave(rowData: TraceabilityData) {}

  onEmailToCounterparty(rowData: TraceabilityData) {
    this.dialogService.open(EmailToCounterpartyComponent, {
      width: '50%',
      header: this.translocoService.translate(
        'DASHBOARD.ACTION_BUTTONS.SEND_SI_EMAIL'
      ),
      data: { rowData: rowData, period: this.period },
    });
  }

  onDdsSubmissionForm(
    rowData: TraceabilityData,
    editable: boolean,
    mode: DDSModalModesEnum
  ) {
    let component: any;
    let header: string;

    // this.dialogService.open(DdsSubmissionFormModalComponent, {
    //   width: '60%',
    //   header: `${this.translocoService.translate(
    //     mode === DDSModalModesEnum.VIEW ? 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_VIEW_SUBMISSION_FORM_HEADER' : 'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_CREATE_SUBMISSION_FORM_HEADER'
    //   )}`,
    //   data: { editable, prefill, form, mode, rowData: this.dialogData.rowData },
    // });

    switch (mode) {
      case DDSModalModesEnum.SUBMIT:
        component = DdsSubmissionFormModalComponent;
        header = this.translocoService.translate(
          'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_CREATE_SUBMISSION_FORM_HEADER'
        );
        break;
      // case DDSModalModesEnum.SUBMIT:
      //   component = DdsSubmissionConfirmationModalComponent;
      //   header = this.translocoService.translate(
      //     'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.SUBMISSION_CONFIRMATION_FORM_HEADER'
      //   );
      //   break;
      case DDSModalModesEnum.WITHDRAW:
        component = DdsSubmissionConfirmationModalComponent;
        header = this.translocoService.translate(
          'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.WITHDRAW_CONFIRMATION_FORM_HEADER'
        );
        break;
      case DDSModalModesEnum.VIEW:
        component = DdsSubmissionFormModalComponent;
        header = this.translocoService.translate(
          'DASHBOARD.EU_IS_TABLE_COLUMNS.DDS_SUBMISSION_FORM.DDS_VIEW_SUBMISSION_FORM_HEADER'
        );

        break;
      default:
        throw new Error('Invalid mode');
    }
    this.dialogService.open(component, {
      width: '60%',
      header: header,
      data: { rowData: rowData, editable, mode, tableParams: this.tableParams },
    });
  }

  onLinkToConsumersTRN(link = true) {
    if (!this.isTraderLinkingTRN) {
      this.traderStateService.setBuyer(this.selectedItems);
    }

    if (link) {
      this.traderStateService.setSelectedScreen(
        TraderLinkScreenEnum.SELECT_TRADE
      );
      this.eventStateService.isTraderLinkingTRN = link;
      this.eventStateService.isTraderCopyingInProgress = false;
      this.resetTraderCreateSi();
    } else {
      this.resetTraderSettings();
    }
  }

  onConfirmTraderLink() {
    if (
      this.traderStateService.selectedScreen() ===
        TraderLinkScreenEnum.SELECT_TRADE &&
      this.traderStateService.showCreateSiModal()
    ) {
      this.traderStateService.setSelectedScreen(
        TraderLinkScreenEnum.CONFIRMATION_DETAILS
      );
    } else if (
      this.traderStateService.selectedScreen() ===
      TraderLinkScreenEnum.SELECT_TRADE
    ) {
      this.showTraderSummaryScreen();
    } else if (
      this.traderStateService.selectedScreen() ===
      TraderLinkScreenEnum.CONFIRMATION_DETAILS
    ) {
      this.traderStateService.setCreateNewSiAction(
        this.traderStateService.createNewSiAction() === 'next'
          ? 'submit'
          : 'next'
      );
    } else {
      // #1 Prepare requests
      const buyers = this.traderStateService.buyer();
      const selectedSellersIds =
        this.selectedItems && this.selectedItems.length > 0
          ? this.selectedItems.map(({ sidetail_id }) => sidetail_id!)
          : null;
      const selectedSellersTrns =
        this.selectedItems && this.selectedItems.length > 0
          ? this.selectedItems.map(({ si_number }) => si_number!)
          : null;
      const selectedBuyersIds = buyers
        ? buyers.map(({ sidetail_id }) => sidetail_id!)
        : null;
      const selectedBuyersTrns = buyers
        ? buyers.map(({ si_number }) => si_number)
        : null;

      const linkRequest: CopySiRequest = {
        source_sidetail_ids: selectedBuyersIds ?? null,
        destination_sidetail_ids: selectedSellersIds ?? null,
      };

      if (
        linkRequest.source_sidetail_ids &&
        linkRequest.source_sidetail_ids.length > 0 &&
        linkRequest.destination_sidetail_ids &&
        linkRequest.destination_sidetail_ids.length > 0
      ) {
        this.eventStateService.isTraderCopyingInProgress = true;
        // #2 Execute async API trader link
        this.dashboardService
          .copyTraderProducerSiDetails(linkRequest)
          .subscribe({
            next: (response: CopySiResponse) => {
              if (response?.task_id) {
                // #3 Show toast message
                this.utilityService.emitToast({
                  message: this.translocoService.translate(
                    'DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.TOAST_SUCCESS_2',
                    { value: selectedBuyersTrns?.join(', ') }
                  ),
                  isSuccess: true,
                });
              } else if (response?.message) {
                this.utilityService.emitToast({
                  message: response.message,
                  isSuccess: false,
                });
              } else {
                this.utilityService.emitToast({
                  message: `There is an error encountered while copying ${selectedSellersTrns?.join(
                    ', '
                  )} to ${selectedBuyersTrns?.join(
                    ''
                  )}. Please try again later.`,
                  isSuccess: false,
                });
              }
            },
            error: () => {
              this.utilityService.emitToast({
                message: `There is an error encountered while copying ${selectedSellersTrns?.join(
                  ', '
                )} to ${selectedBuyersTrns?.join('')}. Please try again later.`,
                isSuccess: false,
              });
            },
            complete: () => {
              // #4 Clear trader link settings
              // Back to Sell Tab
              this.eventStateService.isTraderCopyingInProgress = false;
              this.eventStateService.isTraderLinkingTRN = false;
              //
              this.resetTraderSettings();
            },
          });
      } else {
        console.error(
          `Missing CopySiRequest parameters ${JSON.stringify(linkRequest)}`
        );
      }
    }
  }

  onCancelTraderLink() {
    if (
      this.traderStateService.selectedScreen() === TraderLinkScreenEnum.SUMMARY
    ) {
      this.columns = TRADER_LINK_SUMMARY_SI_NUMBER_TABLE_COLUMNS;
      if (this.traderStateService.showCreateSiModal()) {
        this.traderStateService.setSelectedScreen(
          TraderLinkScreenEnum.CONFIRMATION_DETAILS
        );
      } else {
        this.traderStateService.setSelectedScreen(
          TraderLinkScreenEnum.SELECT_TRADE
        );
        this.isLoadPersistedFilters = true;
        this.dt.first = this.tableParams.page;
        this.lazyLoadTraceabilityTable();
      }
    } else if (
      this.traderStateService.selectedScreen() ===
      TraderLinkScreenEnum.CONFIRMATION_DETAILS
    ) {
      if (
        this.traderStateService.createNewSiAction() === 'next' ||
        this.traderStateService.createNewSiAction() === 'submit'
      ) {
        this.traderStateService.setCreateNewSiAction('cancel');
      } else {
        this.onLinkToConsumersTRN();
      }
    } else {
      this.onLinkToConsumersTRN(false);
    }
  }

  // modifySiDetails(rowData: TraceabilityData) {
  //   this.dialogService.open(EmailToCounterpartyComponent, {
  //     width: '50%',
  //     header: this.translocoService.translate(
  //       'DASHBOARD.ACTION_BUTTONS.SEND_SI_EMAIL'
  //     ),
  //     data: { rowData: rowData, period: this.period },
  //   });
  // }

  private getSelectableRows(): TraceabilityData[] {
    return this.rowData.filter(row => !this.hasMissingOrPendingPlantation(row));
  }

  private getRemovableRows(): TraceabilityData[] {
    return this.rowData.filter(row => !this.hasMissingOrPendingPlantation(row));
  }

  private updateRowCheckedStates(): void {
    const selectedItemKeys = new Set(this.selectedItemNames);
    this.rowData.forEach((row) => {
      row.checked = selectedItemKeys.has(row[this.tableDataKey as keyof TraceabilityData]!);
    });
  }

  private setSelectedItems(selectedData: TraceabilityData[] | null): void {
    if (selectedData) {
      this.selectedItems = this.applyPlantationFilter(selectedData);
      
      this.selectedItemNames = this.selectedItems.map(
        (item) => item[this.tableDataKey as keyof TraceabilityData]!
      );
    } else {
      this.selectedItems = [];
      this.selectedItemNames = [];
    }

    this.updateRowCheckedStates();
  }

  private applyPlantationFilter(data: TraceabilityData[]): TraceabilityData[] {
    return data.filter(row => !this.hasMissingOrPendingPlantation(row));
  }
}

export enum TraceabilityDataKeyEnum {
  SI_NUMBER = 'si_number',
  COUNTRY = 'country',
  EU_IS_ID = 'id'
}

export enum LinkPlantationDataKeyEnum {
  PLANTATION_ID = 'plantation_code',
  TRANSACTION_ID = 'id',
}

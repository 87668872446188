<div class="flex my-4 px-4">
    <span class="font-semibold text-lg">{{ 'DASHBOARD.ACTION_BUTTONS.SEND_SI_EMAIL_SUBHEADER' | transloco }}</span>
</div>
<div class="grid modal-table px-4 mb-5">
    <div class="col-12">
        <ng-container>
            <div class="flex flex-column">
                <div class="flex flex-column py-3">
                    <span>{{ 'DASHBOARD.ACTION_BUTTONS.COUNTERPARTY_NAME' | transloco }}</span>
                    <span class="font-bold">{{ counterparty }}</span>
                </div>
                <span>{{ 'DASHBOARD.ACTION_BUTTONS.EMAIL_ADDRESS' | transloco }}</span>
                <span *ngIf="isLoading" class="loading-icon pi pi-spin pi-spinner"></span>
                <span *ngIf="!isLoading" class="my-1 font-bold" >{{ formattedCounterpartyEmails }}</span>
            </div>
        </ng-container>
    </div>
</div>
<div class="flex justify-content-end modal-action-section">
    <p-button class="mr-4 mt-4" [outlined]="true" (onClick)="closeModal()">{{ 'DASHBOARD.ACTION_BUTTONS.CANCEL' | transloco }}</p-button>
    <p-button class="mr-4 mt-4" [disabled]="counterpartyEmails.length === 0" (onClick)="sendEmailToCounterparty()">{{ 'DASHBOARD.ACTION_BUTTONS.CONFIRM' | transloco
        }}</p-button>
</div>
import { ApplicationRef, createComponent, Injectable, SecurityContext } from '@angular/core';
import { DdrHtmlPdfRequest } from 'src/app/models/ddr-download.model';
import { DdsModalTemplateComponent } from '../dds-modal-template/dds-modal-template.component';
import { map, take } from 'rxjs';
import * as DdsMOdalTemplateComponentWrapper from '../dds-modal-template/dds-modal-template-wrapper.component.html'
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
@Injectable({
  providedIn: 'root'
})
export class GenerateDdsModalService {

  constructor(
    private appRef: ApplicationRef,
    private sanitizer: DomSanitizer,
    private translocoService: TranslocoService
  ) { }

  generateDdrHtmlPayload(payload: DdrHtmlPdfRequest) {
    const componentRef = createComponent(DdsModalTemplateComponent, {
      environmentInjector: this.appRef.injector,
    });

    this.appRef.attachView(componentRef.hostView);

    (componentRef.instance as DdsModalTemplateComponent).data = payload;
    return componentRef.instance.ready.pipe(take(1), map(() => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      const innerHtml = componentRef.location.nativeElement.innerHTML;
      // Use this SecurityContext if contents are hard-coded. Not recommended for user inputs due to XSS
      const wrapper = this.sanitizer.sanitize(SecurityContext.NONE, DdsMOdalTemplateComponentWrapper.default) ?? '';
      //
      const isChinese = this.translocoService.getActiveLang().toLowerCase() === 'cn';
      const noToSansLink = isChinese ? '<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap" rel="stylesheet" />' : '';
      const fontStyle = isChinese ? 'style="font-family: \'Noto Sans SC\', sans-serif; font-display: swap"' : 'style="font-family: Calibri"';
        return wrapper
                .replace('{{ body }}', innerHtml)
                .replace('{{ no_to_sans_link }}', noToSansLink)
                .replace('{{ font_style }}', fontStyle);
    }));
  }
}

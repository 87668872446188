<div class="wrapper">
  <div class="header">
    <div class="logo-container">
      <img alt="agridence-logo" src="https://assets.agridence.com/logos/agd-logo.png"/>
    </div>
    <div class="title-container">
      <div class="title">
        <label>{{ "DASHBOARD.DDR.DUE_DILIGENCE_REPORT" | transloco }}</label>
      </div>
      <div class="sub-title">
        <label>{{ "DASHBOARD.DDR.REFERENCE_NUMBER" | transloco }}: %Reference_Number% </label>
      </div>
    </div>
  </div>
  <div class="section-wrapper first-section-wrapper">
    <div class="section-container">
      <div class="section-title">
        <span>{{ "DASHBOARD.DDR.SUMMARY" | transloco }}</span>
      </div>
      <div class="section-total">
        <div class="section-total-item">
          <div class="section-row-item-container">
            <span class="section-total-item-title">{{ "DASHBOARD.DDR.TOTAL_PLANTATIONS" | transloco }}:</span>
          </div>
          <div class="section-row-item-value-container">
            <span class="section-total-item-value">{{ data.polygons + data.gps_point }}</span>
          </div>
        </div>
        <div class="section-total-item">
          <div class="section-row-item-container">
            <span class="section-total-item-title">{{ "DASHBOARD.DDR.TOTAL_HECTARAGE" | transloco }}:</span>
          </div>
          <div class="section-row-item-value-container">
            <span class="section-total-item-value">{{ data.total_hectarage }}</span>
          </div>
        </div>
        <div class="section-total-item">
          <div class="section-row-item-container">
            <span class="section-total-item-title">{{ "DASHBOARD.DDR.TOTAL_VOLUME" | transloco }}:</span>
          </div>
          <div class="section-row-item-value-container">
            <span class="section-total-item-value">{{ data.total_volume | number : "1.2-2" }} MT</span>
          </div>
        </div>
      </div>
      <div class="section-rows">
        <div class="section-row">
          <div class="section-row-items">
            <div class="section-row-item-container">
              <div class="polygons-container">
                <img class="polygons-icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA5CAYAAAB0+HhyAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAOOSURBVGhD3Zq/b9NAFMfPaZogVaq6pAMCCTF0AJZ0gA6VQgeYYIJ/sRNjGaADUkSHsDRLyYCEqETFQAYgUGicNuG+rh3Fzv1453tO0n6WNlbl+nt+73N+boORRFwDSvHXK0/hQdqfzkXn+Dz+VByFlVZ/IMTe+7446Q6jz1v3y2LrwXL0fREUEqT7Yyj2Dvqi9zc+EFNbK4nn2xWxuhLER/hgD4JSah0NojuiYnVFiGfbVbEuQ3HCFgQXjgAIQoG71FiC9E5HUSl1f7qd6t6dJdHYrIgqQx7vIF+/X4jXB6G2lGyg1F7u3PDuG68gKKXWR3+14o40NpflHSrHR9zJFSSrVi58+sY5iE6tXORVtFMQm1q5yKNoUhBXtXLhUmrWIHnVygVV0cYgvmrlgqJobRAutXJhU/RUkKLUyoWub1JBilYrFypFj4PMSq1cZBUdnIWj0TzUykVSaqUTaabOl6sZAmCMRhVFpYW94tW7M/F7wXsjy61aIJ4+qka9Mu4RhGkehuLzt8W0VZb6htwo65X4k0K/raNQ7h8X8afF43I/Kcv9JK3gqSAAO/r+h3DhSs20wyuDgEXrG9szlzZIQrMdSjXPt9Qa9bLsCU2CGGsQMK/NEqX05GFF3F5fio/oIQUBsy61SbVSIAcBs1J0Vq0UpoLgYquVwDjIFKVonVonwfWB7J1KBcF23zwcyCD2QYZb0ZThKRn0VNc3DtJspx8cbYMM4OobyjirGvQmZ5Pg15/hyDSTU14A+CjaplaYcr/V1/ZlsgjRw7x8lI8OqsAq7L6Vw1ZcmyrQmI36snFFs6CUXuxUjCEw6O2++WeUS+90KPry+qPSQmrYqHOsX9XsIKOCWmoUtVL2rkm7pZqdYiNbqSGMSdE2teLCbYOeym5T+qXYiNac6UVR/fIsWATbOzSd3aaCgMsThvKE+tqk6hKLEsgfoarVVEqmBVQGSbCVGk6IMqtvmBVt32Dt79BsdjMGAZSmoyhaBc5pe4eGO095cLQGARQbuf45gPIOzeXBkRQEYPUoiqasnqtaKZCDJPgoGheeR60UnIMAiqLv3iyJx9IwSVn4qJVCriDARdH4GQQ3lRJlbzKRO0gCx2xCmclteAcBlOZVQZUDBZYggKLoSVxnchtsQQBF0SDPTG6DNUiCrm/yqpVCIUFAVtE+aqVQWBCQKLq2FniplUKhQWYJ77+xzZFrEkSI/7qSDZ/BhcdnAAAAAElFTkSuQmCC"
                  alt="">
                <span>{{ "DASHBOARD.DDR.POLYGONS" | transloco }}:</span>
                <span class="polygons-value">{{ data.polygons }}</span>
              </div>
            </div>
          </div>
          <div class="section-row-items">
            <div class="section-row-item-container">
              <div class="gps-point-container">
                <img class="gps-point-icon"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAA7CAYAAADrYNMhAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAPrSURBVGhD1ZrNTxNBGIenFLpEAoHEclBMQBOJlEs9WA4ggUS40JN4UU/+Yf4DxhOcNNFoSMRTTVSMKGJC9UBNICUo/VhwfvvJdj9mdme2uzyXdttmed7Zd953dpbMGYVcUHqM1wvJhZaXmjb141Oyt39Kjuhro0VIs6l/PjhAiJLrIUMDGfo+Q0aH5YyZkDwEv1fb5EdVJdWaLszDEA1mLJ8ltyay5Npo1vg0PJHkIVn52iKV7Ta3sB8IZGa6j0yN9xqf8BNaHsKbn1rC0p1ECYJbvn58Rl68b2rpESdT41ktCMwPFlzye/sqWd9oSh9tP3AVVhf6mQEw5bd+tumId8n6HAhgZVYJrEyB8kmJmyh9hNxf9A/ANyykSpLiAGm6vtHQ5psXnvL48Us6OdNA/ZiQNTrfvPCURyms/zUOUkDt8JQ6uQNwye/8Ummuq8ZReqhsq670cclj1NOI1tW3nW4OeUzS2iGz7CfG1q7q6DUO+S/0yzQD8a1d294hX6Ujn3Z2qvbyxJJHusisMGgw+eEMXfpmtPeyQOUxU8fqsJgMbypt7UMR0Nbv3cm51umfd9vkbUXOavThst51rZH362JhMBdUXjcYhYle8mi5X8pV+ENHH9hpcyAuz1oJ4ru7RXH72kGHfKMlJo/c5lmD4wqIjn7DaLa2fFNQftQ6FZMwv/XixHAVO0tElD72FQqiaWSJNPkwVURGcQCWvJITG43O1u0HxEXvg4cGdG1LnmeyBQHxdx/Z9wC4iRcFJRlIkwcfvqm+q1IEhzszGbsP+RG9j1gdFivK56/FRwVgZLCFMWhcXjQV3rTi4cmK3k8seZz46do/aX8gLi7T9dJj2qmBPWG1hZR1mFoKE/bSw2Fbmg6/X9htbly1HR3yWFDJXL7K5voVfZvcxJUnxZvRt5zjpjjpzAy3/GQulaOPhV/nUtslD3EZy1bZLJUU452NSx5g2TqW9/wqEdAzvJqor2FaKg8aHvbrvfCVR36lYfKWAh40BObGfDGXaONCuhQCHvMwzcqzyVSfoHQxYcrjkiVRfXge63DlBKpPN/N/vtjLFAfcCY3870b5nCmgUPBd6VA25TmFDF4yDmJAf4yZM47YhJLHxH2w2B9LANjXnL/NLw5C5wFyUXYA2jYhPWfYqhYpic0AZJRQTZxWlijnijwDEcDqgiIUgCnOU1m8iCwP8iM9kQMQFQfMx/c8YCPp2asTcsT5cEKGOJAiD3gDQFUpzyrC4kCaPGAFgLuh8pyciQ6kygMEgMfteHZ0HjSgpVK4Os5CujzQtvY2G2Tntx4AWn6YzslLLPIm+H8B7D7zrlXCEqt8vBDyH9TsjxAL9lSDAAAAAElFTkSuQmCC"
                  alt="">
                <span>{{ "DASHBOARD.DDR.GPS_POINTS" | transloco }}:</span>
                <span class="gps-point-value">{{ data.gps_point }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-rows summary-container">
        <div class="section-row">
          <div class="section-row-items">
            <div class="section-row-item-container">
              <span>{{ "DASHBOARD.DDR.DATA_COLLECTED_IN_ACCORDANCE" | transloco }}</span>
            </div>
            <div class="section-row-item-value-container">
              <span class="section-row-item-value">
                <i *ngIf="data.summary_data_collected" class="check-icon"></i>
                <a *ngIf="!data.summary_data_collected" class="close-icon">&#x2715;</a>
              </span>
            </div>
          </div>
          <div class="border-line"></div>
          <div class="section-row-items">
            <div class="section-row-item-container">
              <span>{{ "DASHBOARD.DDR.PRODUCE_IN_ACCORDANCE" | transloco }}</span>
            </div>
            <div class="section-row-item-value-container">
              <span class="section-row-item-value">
                <i *ngIf="data.summary_produced" class="check-icon"></i>
                <a *ngIf="!data.summary_produced" class="close-icon">&#x2715;</a>
              </span>
            </div>
          </div>
          <div class="border-line"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-container">
      <div class="section-title">
        <span>{{ "DASHBOARD.DDR.SHIPPING_INSTRUCTION_AND_COUNTRY_OF_ORIGIN" | transloco }}</span>
      </div>
      <div class="section-rows">
        <div class="section-row">
          <div *ngFor="let item of data.selected_si; let i = index" class="section-row-items"
            [ngClass]="{ 'section-row-margin-top': i > 1 }">
            <div class="section-row-item-container si-numb-row">
              <span>{{ item.si_number }}</span>
            </div>
            <ng-container *ngIf="item.summary?.countries?.length;">
              <div *ngIf="item.summary?.countries?.length" class="section-row-item-value-container">
                <span *ngFor="let country of item.summary?.countries; last as last" class="section-row-item-value">{{
                  country }}</span>
              </div>
            </ng-container>
          </div>
          <div class="border-line"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-container">
      <div class="section-title">
        <span>{{ "DASHBOARD.DDR.OVERALL_RISK_OVERLAPS" | transloco }}</span>
      </div>
      <div class="section-rows">
        <div class="section-row">
          <div class="section-row-items">
            <div class="section-row-item-container risk-item">
              <div class="section-icon-container">
                <span class="warn warning"></span>
              </div>
              <span style="word-wrap:normal;">Tree Cover Loss (Hansen et al.)</span>
            </div>
            <div class="section-row-item-value-container">
              <span class="section-row-item-value">{{ data.tree_cover_loss }}%</span>
            </div>
          </div>
          <div class="border-line"></div>
          <div class="section-row-items">
            <div class="section-row-item-container risk-item">
              <div class="section-icon-container">
                <span class="warn warning green"></span>
              </div>
              <span>Protected Areas</span>
            </div>
            <div class="section-row-item-value-container">
              <span class="section-row-item-value">{{ data.protected_areas }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper has-open-input">
    <div class="section-container">
      <div class="section-title">
        <span>{{ "DASHBOARD.DDR.OPERATOR_INFORMATION" | transloco }}</span>
      </div>
      <div class="section-rows">
        <div class="section-row">
          <ng-container *ngFor="let item of data.company_info;">
            <div class="section-row-items">
              <div class="section-row-item-container">
                <span>{{ item.title }}</span>
              </div>
              <div class="section-row-item-value-container">
                <span class="section-row-item-value">{{ item.value }}</span>
              </div>
            </div>
            <div class="border-line"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper has-open-input">
    <div class="section-container">
      <div class="section-title">
        <span>{{ "DASHBOARD.DDR.COMMODITY_INFORMATION" | transloco }}</span>
      </div>
      <div class="section-rows">
        <div class="section-row">
          <ng-container *ngFor="let item of data.commodity_info;">
            <div class="section-row-items">
              <div class="section-row-item-container">
                <span>{{ item.title }}</span>
              </div>
              <div class="section-row-item-value-container">
                <span class="section-row-item-value">{{ item.value }}</span>
              </div>
            </div>
            <div class="border-line"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper has-open-input" *ngFor="let sectionItem of data.additional_info">
    <div class="section-container">
      <div class="section-title">
        <span>{{ sectionItem.section }}</span>
      </div>
      <div class="section-rows">
        <div class="section-row">
          <ng-container *ngFor="let item of sectionItem.info;">
            <div class="section-row-items">
              <div class="section-row-item-container">
                <span>{{ item.title }}</span>
              </div>
              <div class="section-row-item-value-container">
                <span class="section-row-item-value">{{ item.value }}</span>
              </div>
            </div>
            <div class="border-line"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-container">
      <div class="section-title">
        <span>{{ "DASHBOARD.DDR.ACKNOWLEDGEMENT" | transloco }}</span>
      </div>
      <div class="disclaimer-content">
        <span>{{ "DASHBOARD.DDR.DISCLAIMER_PARAGRAPH" | transloco }}</span>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-container">
      <div class="sign-section-container">
        <div>
          <span class="processor-name">{{ data.processor_name }}</span>
          <div class="sign-data-field">
            <span>{{ "DASHBOARD.DDR.NAME" | transloco }}</span>
          </div>
        </div>
        <div class="date-wrapper">
          <span class="process-date">{{ data.process_date }}</span>
          <div class="sign-data-field">
            <span>{{ "DASHBOARD.DDR.DATE" | transloco }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-wrapper">
    <div class="section-container">
      <div class="disclaimer-content">
        <span>{{ "DASHBOARD.DDR.SHIPMENTS_DISCLAIMER" | transloco }}</span>
      </div>
    </div>
  </div>
</div>
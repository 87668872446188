import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastProps } from 'src/app/models/toast.model';
import { PlantationsBySi } from 'src/app/models/traceability-state.model';

@Injectable({
  providedIn: 'root',
})
export class EventStateService {
  private _isCreateNewSiloading$ = new BehaviorSubject<boolean>(false);
  private _isNewSiCreated$ = new Subject<boolean>();
  private _toast$ = new Subject<ToastProps>();
  // for SI table risks
  private _isRiskLoading$ = new BehaviorSubject<boolean>(false);
  // for plantation risks
  private _isPlantationRiskLoading$ = new BehaviorSubject<boolean>(false);
  private _isPlantationGeometryLoading$ = new BehaviorSubject<boolean>(false);
  private _error$ = new BehaviorSubject<HttpErrorResponse | null>(null);
  private _hasError$ = new BehaviorSubject<boolean>(false);
  private _isPlantationBySiLoaded$ = new BehaviorSubject<boolean>(false);
  private _isSiSummariesLoaded$ = new BehaviorSubject<boolean>(false);
  private _isSourcingAreaLoaded$ = new BehaviorSubject<boolean>(false);
  private _downloadEuisLoading$ = new BehaviorSubject<boolean>(false);
  private _downloadCombinedEuisLoading$ = new BehaviorSubject<boolean>(false);
  private _isTraceabilityTableLoading$ = new BehaviorSubject<boolean>(true);
  private _downloadFullRiskLoading$ = new BehaviorSubject<boolean>(false);
  private _downloadEudrPackageLoading$ = new BehaviorSubject<boolean>(false);
  private _isLinkPlantationLoading$ = new BehaviorSubject<boolean>(false);
  private _isPlantationLinked$ = new Subject<boolean>();
  private _isDownloadDdrLoading$ = new BehaviorSubject<boolean>(false);
  private _isVerifyingSecretKeyLoading$ = new BehaviorSubject<boolean>(false);
  private _isDownloadingExternalJsonFileLoading$ = new BehaviorSubject<boolean>(false);
  private _isDownloadingExternalZipFileLoading$ = new BehaviorSubject<boolean>(false);
  private _isSettingsLoaded$ = new BehaviorSubject<boolean>(false);
  private _isDdrDownloaded$ = new Subject<boolean>();
  private _zoomPlantation$ = new Subject<PlantationsBySi[]>();
  private _traceabilityTableParams$ = new BehaviorSubject<any>(null);
  private _hoveredPlantation$ = new Subject<PlantationsBySi | null>();
  private _isPlantationFilterLoading$ = new BehaviorSubject<boolean>(false);

  private _isDashboardTableLoading$ = new BehaviorSubject<boolean>(false);
  private _isEuisAdapterListLoading$ = new BehaviorSubject<boolean>(false);

  get isEuisAdapterListLoading$() {
    return this._isEuisAdapterListLoading$.asObservable();
  }

  set isEuisAdapterListLoading(isLoading: boolean) {
    this._isEuisAdapterListLoading$.next(isLoading);
  }

  get isCreatedNewSiLoading$() {
    return this._isCreateNewSiloading$.asObservable();
  }

  set isCreatedNewSiLoading(isLoading: boolean) {
    this._isCreateNewSiloading$.next(isLoading);
  }

  get isVerifyingSecretKeyLoading$() {
    return this._isVerifyingSecretKeyLoading$.asObservable();
  }

  set isVerifyingSecretKeyLoading(isLoading: boolean) {
    this._isVerifyingSecretKeyLoading$.next(isLoading);
  }

  get isDownloadingExternalJsonFileLoading$() {
    return this._isDownloadingExternalJsonFileLoading$.asObservable();
  }

  set isDownloadingExternalJsonFileLoading(isLoading: boolean) {
    this._isDownloadingExternalJsonFileLoading$.next(isLoading);
  }

  get isDownloadingExternalZipFileLoading$() {
    return this._isDownloadingExternalZipFileLoading$.asObservable();
  }

  set isDownloadingExternalZipFileLoading(isLoading: boolean) {
    this._isDownloadingExternalZipFileLoading$.next(isLoading);
  }

  get isPlantationGeometryLoading$() {
    return this._isPlantationGeometryLoading$.asObservable();
  }

  set isPlantationGeometryLoading(isLoading: boolean) {
    this._isPlantationGeometryLoading$.next(isLoading);
  }

  get isLinkPlantationLoading$() {
    return this._isLinkPlantationLoading$.asObservable();
  }

  set isLinkPlantationLoading(isLoading: boolean) {
    this._isLinkPlantationLoading$.next(isLoading);
  }

  get isSettingsLoaded$() {
    return this._isSettingsLoaded$.asObservable();
  }

  set isSettingsLoaded(isLoading: boolean) {
    this._isSettingsLoaded$.next(isLoading);
  }

  get isPlantationLinked$() {
    return this._isPlantationLinked$.asObservable();
  }

  set isPlantationLinked(isLinked: boolean) {
    this._isPlantationLinked$.next(isLinked);
  }

  get isDownloadDdrLoading$() {
    return this._isDownloadDdrLoading$.asObservable();
  }

  set isDownloadDdrLoading(isLoading: boolean) {
    this._isDownloadDdrLoading$.next(isLoading);
  }

  get isDdrDownloaded$() {
    return this._isDdrDownloaded$.asObservable();
  }

  set isDdrDownloaded(isLinked: boolean) {
    this._isDdrDownloaded$.next(isLinked);
  }

  get zoomPlantation$() {
    return this._zoomPlantation$.asObservable();
  }

  set zoomPlantation(plantation: PlantationsBySi[]) {
    this._zoomPlantation$.next(plantation);
  }

  get traceabilityTableParams$() {
    return this._traceabilityTableParams$.asObservable();
  }

  set traceabilityTableParams(filters: any) {
    this._traceabilityTableParams$.next(filters);
  }

  get isNewSiCreated$() {
    return this._isNewSiCreated$.asObservable();
  }

  set isNewSiCreated(isCreated: boolean) {
    this._isNewSiCreated$.next(isCreated);
  }

  get toast$() {
    return this._toast$.asObservable();
  }

  set toast(toast: ToastProps) {
    this._toast$.next(toast);
  }

  get isRiskLoading$() {
    return this._isRiskLoading$.asObservable();
  }

  set isRiskLoading(isRiskLoading: boolean) {
    this._isRiskLoading$.next(isRiskLoading);
  }

  get isPlantationRiskLoading$() {
    return this._isPlantationRiskLoading$.asObservable();
  }

  set isPlantationRiskLoading(isPlantationRiskLoading: boolean) {
    this._isPlantationRiskLoading$.next(isPlantationRiskLoading);
  }

  get isSiSummariesLoaded$() {
    return this._isSiSummariesLoaded$.asObservable();
  }

  set isSiSummariesLoaded(isSiSummariesLoaded: boolean) {
    this._isSiSummariesLoaded$.next(isSiSummariesLoaded);
  }

  get isPlantationBySiLoaded$() {
    return this._isPlantationBySiLoaded$.asObservable();
  }

  set isPlantationBySiLoaded(isPlantationBySiLoaded: boolean) {
    this._isPlantationBySiLoaded$.next(isPlantationBySiLoaded);
  }

  get isSourcingAreaLoaded$() {
    return this._isSourcingAreaLoaded$.asObservable();
  }

  set isSourcingAreaLoaded(isSourcingAreaLoaded: boolean) {
    this._isSourcingAreaLoaded$.next(isSourcingAreaLoaded);
  }

  get error$() {
    return this._error$.asObservable();
  }

  set error(error: HttpErrorResponse | null) {
    this._error$.next(error);
  }

  get downloadEuisLoading$() {
    return this._downloadEuisLoading$.asObservable();
  }

  set downloadEuisLoading(downloadEuisLoading: boolean) {
    this._downloadEuisLoading$.next(downloadEuisLoading);
  }

  get downloadCombinedEuisLoading$() {
    return this._downloadCombinedEuisLoading$.asObservable();
  }

  set downloadCombinedEuisLoading(downloadEuisLoading: boolean) {
    this._downloadCombinedEuisLoading$.next(downloadEuisLoading);
  }

  get downloadFullRiskLoading$() {
    return this._downloadFullRiskLoading$.asObservable();
  }

  set downloadFullRiskLoading(downloadFullRiskLoading: boolean) {
    this._downloadFullRiskLoading$.next(downloadFullRiskLoading);
  }

  get downloadEudrPackageLoading$() {
    return this._downloadEudrPackageLoading$.asObservable();
  }

  set downloadEudrPackageLoading(downloadEudrPackageLoading: boolean) {
    this._downloadEudrPackageLoading$.next(downloadEudrPackageLoading);
  }

  get hoveredPlantation$() {
    return this._hoveredPlantation$.asObservable();
  }

  set hoveredPlantation(hoveredPlantation: PlantationsBySi | null) {
    this._hoveredPlantation$.next(hoveredPlantation);
  }

  get isTraceabilityTableLoading$() {
    return this._isTraceabilityTableLoading$.asObservable();
  }

  set isTraceabilityTableLoading(isTraceabilityTableLoading: boolean) {
    this._isTraceabilityTableLoading$.next(isTraceabilityTableLoading);
  }

  get isPlantationFilterLoading$() {
    return this._isPlantationFilterLoading$.asObservable();
  }

  set isPlantationFilterLoading(isPlantationFilterLoading: boolean) {
    this._isPlantationFilterLoading$.next(isPlantationFilterLoading);
  }

  get isDashboardTableLoading$() {
    return this._isDashboardTableLoading$.asObservable();
  }

  set isDashboardTableLoading(isDashboardTableLoading: boolean) {
    this._isDashboardTableLoading$.next(isDashboardTableLoading);
  }

  get hasError$() {
    return this._hasError$.asObservable();
  }

  set hasError(hasError: boolean) {
    this._hasError$.next(hasError);
  }

  resetAllLoadingStates() {
    this.downloadEuisLoading = false;
    this.downloadFullRiskLoading = false;
    this.isRiskLoading = false;
    this.isCreatedNewSiLoading = false;
    // this.isSiSummariesLoaded = false;
    this.downloadEudrPackageLoading = false;
    this.isPlantationGeometryLoading = false;
    this.isPlantationRiskLoading = false;
    this.isDownloadDdrLoading = false;
  }

  constructor() {}
}

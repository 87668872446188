import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  GradeOptions,
  SymbolOptions
} from 'src/app/models/traceability-state.model';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { Subject, takeUntil } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import { CreateNewSiPayload } from 'src/app/models/create-new-si-payload.model';
import { TranslocoPipe } from '@jsverse/transloco';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { CompanyTypesEnum } from 'src/app/enums/company-types.enum';
import { UserInfoStateService } from 'src/app/services/state-service/user-info-state.service';

@Component({
  selector: 'app-creat-new-si-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
  templateUrl: './creat-new-si-form.component.html',
  styleUrls: ['./creat-new-si-form.component.scss'],
})
export class CreatNewSiFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) isTradeConfirmation!: boolean;
  @Input({ required: true }) selectedItem!: any;
  @Output() formValidity = new EventEmitter<boolean>();
  @Output() formValues = new EventEmitter<CreateNewSiPayload>();
  createNewSiFormGroup!: FormGroup;
  destroyed$ = new Subject<void>();

  symbolOptions: SymbolOptions[] = [];
  gradeOptions: GradeOptions[] = [];
  companyName: string = ';'
  companyType: CompanyTypesEnum | null = null;

  test = [
    {
      label: 'test',
      value: 'test',
    },
  ];

  get siNumber() {
    return this.createNewSiFormGroup.get('siNumber') as FormControl;
  }

  get volume() {
    return this.createNewSiFormGroup.get('volume') as FormControl;
  }

  get symbol() {
    return this.createNewSiFormGroup.get('symbol') as FormControl;
  }

  get grade() {
    return this.createNewSiFormGroup.get('grade') as FormControl;
  }

  get deliveryMonth() {
    return this.createNewSiFormGroup.get('deliveryMonth') as FormControl;
  }

  get isFieldRequired() {
    return this.isTradeConfirmation ? [] : [Validators.required];
  }

  constructor(
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private datePipe: DatePipe,
    private traceabilityStateService: TraceabilityStateService,
    private userInfoStateService: UserInfoStateService
  ) {}

  ngOnInit(): void {
    this.initializeCreateNewSiForm();
    this.initializeUserInfo();
    this.listenToFormChange();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getFormBuilder() {
    return this.formBuilder;
  }

  initializeUserInfo() {
    this.userInfoStateService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.companyName = res?.company.name || '';
        this.companyType = res?.company.type as CompanyTypesEnum ?? null;
      });
    this.userInfoStateService.userViewAs$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (!this.isTradeConfirmation) {
          this.traceabilityStateService.getCounterpartyOptions(res, this.selectedItem.id);
        }
      });
    this.traceabilityStateService.symbolOptions$
    .pipe(takeUntil(this.destroyed$))
    .subscribe((options: any) => {
      this.symbolOptions = options;
    });
    this.traceabilityStateService.gradeOptions$
    .pipe(takeUntil(this.destroyed$))
    .subscribe((options: any) => {
      this.gradeOptions = options;
    });
  }

  listenToFormChange() {
    this.createNewSiFormGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const optionalRequiredFields =
          this.isTradeConfirmation ||
          (this.symbol.valid && this.grade.valid && this.deliveryMonth.valid);
        this.formValidity.emit(
          this.siNumber.valid && this.volume.valid && optionalRequiredFields
        );
        this.formValues.emit(this.getPayload());
      });
  }

  initializeCreateNewSiForm() {
    this.createNewSiFormGroup = this.formBuilder.group({
      siNumber: ['', Validators.required],
      counterParty: {
        value: this.isTradeConfirmation
          ? this.selectedItem?.counterparty
          : this.selectedItem?.name,
        disabled: true,
      },
      symbol: this.formBuilder.control(
        {
          value: this.selectedItem?.factory?.factory_code,
          disabled: this.isTradeConfirmation,
        },
        this.isFieldRequired
      ),
      grade: this.formBuilder.control(
        {
          value: this.selectedItem?.grade?.grade_code,
          disabled: this.isTradeConfirmation,
        },
        this.isFieldRequired
      ),
      volume: [this.selectedItem?.volume, [Validators.required]],
      deliveryMonth: this.formBuilder.control(
        {
          value: this.utilityService.getDateMonth(
            this.selectedItem?.delivery_date
          ),
          disabled: this.isTradeConfirmation,
        },
        this.isFieldRequired
      ),
      emailAddress: { value: '', disabled: true },
    });
  }

  getPayload(): CreateNewSiPayload {
    if(this.isTradeConfirmation) {
      return {
        si_numb: this.siNumber.value,
        producer: this.selectedItem?.producer?.name,
        counterparty: this.selectedItem?.counterparty,
        factory_cd: this.selectedItem?.factory.factory_code,
        contract_id: this.selectedItem?.id.toString(),
        contract_numb: this.selectedItem?.contract_number,
        contract_buyer_nm: this.selectedItem?.consumer?.name,
        grade_nm: this.selectedItem.grade.grade_code,
        delivery_date: this.datePipe.transform(
          this.selectedItem.delivery_date,
          'yyyy-MM-dd'
        )!,
        weight: Number(this.volume.value),
      };
    } else {
      const viewAs = this.utilityService.retrieveFromObservable(this.userInfoStateService.userViewAs$) as CompanyTypesEnum;
      return {
        si_numb: this.siNumber.value,
        producer: viewAs === CompanyTypesEnum.Producer ? this.companyName : this.selectedItem.name,
        contract_buyer_nm: viewAs === CompanyTypesEnum.Producer ? this.selectedItem.name : this.companyName,
        factory_cd: this.createNewSiFormGroup.value.symbol,
        grade_nm: this.createNewSiFormGroup.value.grade,
        weight: Number(this.volume.value),
        delivery_date: this.datePipe.transform(
          this.createNewSiFormGroup.value.deliveryMonth,
          'yyyy-MM-dd'
        )!,
      }
    }
  }
}

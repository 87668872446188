import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from '../services/auth.service';
import { TraceabilityStateService } from '../services/state-service/traceability-state.service';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { EventStateService } from '../services/state-service/event-state.service';

@Injectable()
export class TraceabilityInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private eventStateService: EventStateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let authToken: string;
    
    if (window.location.pathname.includes('/offplatformsiview')) {
      const secretKey = this.authService.offPlatformSecretKey || '';
      authToken = secretKey;
    } else {
      authToken = `Token ${this.authService.userInfoToken}`;
    }
    const modifiedRequest = request.clone({
      headers: request.headers.set('Authorization', authToken),
    });

    return next.handle(modifiedRequest).pipe(
      tap(() => {
        this.eventStateService.error = null;
      }),
      // TODO: update error handling - use ARP toast and simplify events
      catchError((error: HttpErrorResponse) => {
        this.eventStateService.error = error;
        this.eventStateService.resetAllLoadingStates();
        this.eventStateService.downloadEuisLoading = false;
        this.eventStateService.downloadFullRiskLoading = false;
        if (
          error.error?.errors?.length &&
          error.error?.errors[0]?.detail === 'provider AgriTrace not supported'
        ) {
          return throwError(() => error.error.detail);
        }

        if (
          error.status === 401 ||
          (error.status === 404 && error.message === 'Not found')
        ) {
          this.router.navigate(['error'], {
            queryParams: { status: error.status, detail: error.error.detail },
          });
          sessionStorage.removeItem('ARP_Token');
          sessionStorage.removeItem('eu_is_secret_key');
          sessionStorage.removeItem('eu_is_user');
        } else {
          this.dialogService.open(ErrorPageComponent, {
            data: error,
            width: '502px',
          });
        }

        return throwError(() => error.error.detail);
      })
    );
  }
}

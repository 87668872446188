export const INITIAL_TRACEABILITY_TABLE_PARAMS = {
  pageSize: 10,
  page: 10,
};

export const POLYGON_DATA_FILTER_OPTIONS = {
  key: 'has_plantation_data',
  options: [
    { label: 'DASHBOARD.HAS_POLYGON_DATA', value: true },
    { label: 'DASHBOARD.NO_POLYGON_DATA', value: false },
  ],
};

export const PUBLISH_STATUS_FILTER_OPTIONS = {
  key: 'publish_status',
  options: [
    { label: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.PUBLISHED', value: 'PUBLISHED' },
    { label: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UNPUBLISHED', value: 'UNPUBLISHED' },
    { label: 'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.DELETED', value: 'DELETED' },
  ],
};

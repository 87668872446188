import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import {
  BehaviorSubject,
  Subject,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  merge,
} from 'rxjs';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import {
  CounterpartiesData,
  INITIAL_PAGED_DATA_STATE,
  TradeConfirmationData,
} from 'src/app/models/traceability-state.model';
import { CreatNewSiFormComponent } from './creat-new-si-form/creat-new-si-form.component';
import { CreateNewSiPayload } from 'src/app/models/create-new-si-payload.model';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  COUNTERPARTY_TABLE_COLUMNS,
  TRADE_CONFIRMATION_TABLE_COLUMNS,
} from 'src/app/constants/table-columns.const';
import { TranslocoPipe } from '@jsverse/transloco';
import { CreateNewSiOptionsEnum } from 'src/app/enums/create-new-options.enum';
import { UtilityService } from 'src/app/services/utility.service';
import { UserViewAsSetting } from 'src/app/models/user-info-state.model';
import { UserInfoStateService } from 'src/app/services/state-service/user-info-state.service';

@Component({
  selector: 'app-create-new-si-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    CreatNewSiFormComponent,
    TranslocoPipe,
  ],
  templateUrl: './create-new-si-modal.component.html',
  styleUrls: ['./create-new-si-modal.component.scss'],
})
export class CreateNewSiModalComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt!: Table;
  columns: ColumnDefinition[] = [];
  isTableDataLoading$ = new BehaviorSubject<boolean>(true);
  rowData: TradeConfirmationData[] | CounterpartiesData[] = [];
  selectedItem!: TradeConfirmationData;
  pageRows: number = 10;
  totalRecords!: number;
  createNewSiPayload!: CreateNewSiPayload;

  private filterInput = new Subject<string>();

  INITIAL_PAGE_SUB_HEADER =
    'DASHBOARD.CREATE_NEW_SI.SUB_HEADER_TRADE_CONFIRMATION';
  FINAL_PAGE_SUB_HEADER =
    'DASHBOARD.CREATE_NEW_SI.SUB_HEADER_FINAL_PAGE_TRADE_CONFIRMATION';
  INITIAL_PAGED_DATA_STATE = INITIAL_PAGED_DATA_STATE;

  isFinalPage: boolean = false;
  isFormValid: boolean = false;
  tableHeader = '';
  createNewSiOptions = CreateNewSiOptionsEnum;

  destroyed$ = new Subject<void>();

  tableParams: any = {
    pageSize: 10,
    page: 1,
  };

  modeParam: { [param: string]: string | number | boolean } | null = null;

  get subHeader() {
    if (this.selectedCreateNewSiOption === CreateNewSiOptionsEnum.TRADE_CONFIRMATION) {
      return this.isFinalPage ? 'DASHBOARD.CREATE_NEW_SI.SUB_HEADER_FINAL_PAGE_TRADE_CONFIRMATION' : 'DASHBOARD.CREATE_NEW_SI.SUB_HEADER_TRADE_CONFIRMATION';
    } else if (this.selectedCreateNewSiOption === CreateNewSiOptionsEnum.COUNTERPARTY) {
      return this.isFinalPage ? 'DASHBOARD.CREATE_NEW_SI.SUB_HEADER_FINAL_PAGE_SUB_HEADER_COUNTERPARTY' : 'DASHBOARD.CREATE_NEW_SI.SUB_HEADER_COUNTERPARTY';
    }
    return '';
  }

  get globalFilterFields() {
    return this.columns.map((c) => c.field);
  }

  get primaryActionText() {
    return this.isFinalPage
      ? 'DASHBOARD.CREATE_NEW_SI.DONE'
      : 'DASHBOARD.CREATE_NEW_SI.NEXT';
  }

  get isPrimaryActionDisabled() {
    return (
      (this.isFinalPage && !this.isFormValid) ||
      (!this.isFinalPage && !this.selectedItem)
    );
  }

  get selectedCreateNewSiOption() {
    return this.dialogConfig.data;
  }

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    public userInfoStateService: UserInfoStateService,
    public utilityService: UtilityService,
    private dashboardService: DashboardService,
    public eventStateService: EventStateService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.initializeTableParams();
    this.initializeDataset();
    this.listenToIsNewSiCreated();

    this.filterInput
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.dt.filterGlobal(value, 'contains');
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.traceabilityStateService.clearCreateNewSiTableData();
    this.filterInput.unsubscribe();
  }

  private initializeTableParams() {
    const viewAsSetting = this.utilityService.retrieveFromObservable(this.userInfoStateService.viewAsSetting$) as UserViewAsSetting;
    this.modeParam = viewAsSetting ? viewAsSetting.param : null;
    this.tableParams = viewAsSetting ? { ...this.tableParams, ...viewAsSetting.param } : this.tableParams;
  }

  initializeDataset() {
    this.isTableDataLoading$.next(true);
    merge(
      this.traceabilityStateService.pagedTradeConfirmationData$,
      this.traceabilityStateService.pagedCounterpartiesData$
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.rowData = res!.results!;
        this.totalRecords = res!.count;
        this.isTableDataLoading$.next(res === INITIAL_PAGED_DATA_STATE);
      });

    if (
      this.selectedCreateNewSiOption ===
      CreateNewSiOptionsEnum.TRADE_CONFIRMATION
    ) {
      this.columns = TRADE_CONFIRMATION_TABLE_COLUMNS;
      this.tableHeader = 'DASHBOARD.CREATE_NEW_SI.TRADE_CONFIRMATION';
      return;
    }

    this.columns = COUNTERPARTY_TABLE_COLUMNS;
    this.tableHeader = 'DASHBOARD.CREATE_NEW_SI.COUNTERPARTY';
  }

  listenToIsNewSiCreated() {
    this.eventStateService.isNewSiCreated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  loadTable(event: TableLazyLoadEvent) {
    this.isTableDataLoading$.next(true);
    const pageNumber = event.first ?? 0;
    const pageSize = event.rows ?? 10;

    this.tableParams = {
      page: Math.floor((pageNumber + pageSize) / pageSize),
      pageSize: pageSize,
      searchTerm: event.globalFilter,
    };
    this.initializeTableParams();

    if (
      this.selectedCreateNewSiOption ===
      CreateNewSiOptionsEnum.TRADE_CONFIRMATION
    ) {
      this.traceabilityStateService.getTradeConfirmationPage(this.userInfoStateService.userViewAs$, this.tableParams);
      return;
    }

    this.traceabilityStateService.getCounterparties(this.tableParams);
  }

  applyFilterGlobal(event: Event) {
    this.filterInput.next((event.target as HTMLInputElement).value);
  }

  onPrimaryActionClicked() {
    this.isFinalPage = true;

    if (this.isFinalPage && this.isFormValid) {
      this.dashboardService.createNewSi(this.createNewSiPayload, this.modeParam);
    }
  }

  onBackClicked() {
    this.isFinalPage = false;
  }

  onFormValidityChanged(isValid: boolean) {
    this.isFormValid = isValid;
  }

  onFormValuesChanged(event: CreateNewSiPayload) {
    this.createNewSiPayload = event;
  }
}
